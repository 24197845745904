import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, BehaviorSubject, merge, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../services';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { validateEmail } from '../../helpers/functions';


interface ViewDialogData {
	title: string;
	data: any;
	targetId: string;
}

@Component({
	selector: 'app-send-email-dialog-modal',
	templateUrl: './custom-send-email-dialog.component.html',
	styleUrls: ['./custom-send-email-dialog.component.scss']
})
export class ModalSendEmailDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public targetId: string;

	readonly separatorKeysCodes = [ENTER] as const;
	addOnBlur = true;
	emails: string[] = [];
	public notes: string = '';

	@ViewChild('targetInput') targetInput!: ElementRef;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<ModalSendEmailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalAudioGalleryDialogComponent', data);
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					if (this.data.hasOwnProperty('targetId')) {
						this.targetId = this.data.targetId;
					}
				}
			})
		);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}
	sendEmail(): void {
		// this.closeModal(undefined);
		if (this.emails.length == 0) {
			this.errorMessage = 'Recipients required.';
		} else {
			if (!this.loading) {
				this.loading = true;
				this.loaderService.display(true);
				this.errorMessage = '';
				this.requestService.sendEmail(this.targetId, this.emails, this.notes, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
					}
					this.loading = false;
					this.loaderService.display(false);
					if (data) {
						this.layoutUtilsService.showNotification('Email Sent', 'Dismiss');
						this.closeModal(undefined);
					}
				});
			}
		}
	}


	add(event: MatChipInputEvent): void {
		this.errorMessage = '';
		let preValue = (event.value || '').trim();

		let arrValue = this.convertedToArray(preValue);
		// console.log(arrValue);
		if (arrValue.length == 0) {
			// this.errorMessage = 'Incorrect Email format.';
		} else if (arrValue.length == 1) {
			let value = arrValue[0];
			// Add our email

			if (value !== '') {
				if (value && validateEmail(value)) {
					if (!this.emails.includes(value)) {
						this.emails.push(value);
						// Clear the input value
						event.chipInput!.clear();
					} else {
						this.errorMessage = 'Already Added.';
					}
				} else {
					this.errorMessage = 'Incorrect Email format.';
				}
			}
		} else {
			let insertedValues = [];
			for (let itm of arrValue) {
				let value = (itm || '').trim();
				if (value && validateEmail(value)) {
					if (!this.emails.includes(value)) {
						this.emails.push(value);
						insertedValues.push(value);
					} else {
						this.errorMessage = 'Remaining emails not accepted.';
					}
				} else {
					this.errorMessage = 'Remaining emails not accepted.';
				}
			}
			if (this.errorMessage === '') {
				// Clear the input value
				event.chipInput!.clear();

			} else {
				let originalInsert = arrValue.filter(itm => !insertedValues.includes(itm));
				let commaSeparatedString = originalInsert.join(", ");
				// event.value = commaSeparatedString;
				this.targetInput.nativeElement.value = commaSeparatedString;
			}
		}
	}

	convertedToArray(str) {
		// Check if the string contains a comma
		if (str.includes(',')) {
			// Split the string by comma and convert it to an array
			let array = str.split(',');
			// Trim whitespace from each element (optional, depending on your requirements)
			array = array.map(item => item.trim());
			return array;
		} else {
			// If no comma is found, return an empty array or a message indicating the string can't be split
			return [str];
		}
	}

	remove(email: string): void {
		this.errorMessage = '';
		const index = this.emails.indexOf(email);

		if (index >= 0) {
			this.emails.splice(index, 1);
		}
		if (this.emails.length == 0) {
			this.errorMessage = 'Recipients required.';
		}
	}
}
