import { Component, OnInit, ViewChild, ChangeDetectorRef, TemplateRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService, SubheaderService } from '../../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { EventData } from '../../../shared/modules/calendar/interface/event-data';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from 'src/app/shared/components/custom-dialog/custom-dialog.component';
import * as moment from 'moment';
import { utc } from 'moment';
import { CalendarComponent } from '../../modules/calendar';
import { formatDate } from '@angular/common';
import { CustomModalViewEventDialogComponent } from '../custom-view-event-dialog/custom-view-event-dialog.component';
import html2canvas from 'html2canvas';
import { getDataFromListContains, getItemFromListContains, sanitizeFilename } from '../../helpers';
import { ModalSchedulerExportDialogComponent } from '../custom-scheduler-export-dialog/custom-scheduler-export-dialog.component';
import { ModalFilesEventDialogComponent } from '../custom-files-event-dialog/custom-files-event-dialog.component';

@Component({
  selector: 'app-custom-scheduler',
  templateUrl: './custom-scheduler.component.html',
  styleUrls: ['./custom-scheduler.component.scss']
})
export class CustomSchedulerComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public selectedUser: any;
  public organization = undefined;
  public isAdmin: boolean = false;
  public isCaseManager: boolean = false;
  public isCaseManagerSuper: boolean = false;
  public isSuperAdmin: boolean = false;
  public originalTableSetting: any = undefined;
  public metaFieldSetting = undefined;
  public tableSetting: any = undefined;
  public settingOrgObject = undefined;
  public loading: boolean = false;
  public permissions = {
    canViewEvent: false,
    canAddEvent: false,
    canEditEvent: false,
    canDeleteEvent: false,
    canCreateEvent: false
  }
  public maxRangeBreakdown: number = 5; // months
  public maxRange: number = 42; // days
  public maxAnalysisRange: number = 1;
  public startfilterRange = undefined;
  public endfilterRange = undefined;
  public menuIsOpen = false;
  public selectedStartDate = undefined;
  public selectedEndDate = undefined;
  public currentStartDate = moment().utc();
  public currentEndDate = moment().utc();
  public lightColors = {
    "1": "#fee0f3",
    "4": "#ffacab",
    "5": "#c2ffe1",
    "7": "#fff29c",
    "8": "#fff0e1",
    "9": "#fee0f3",
    "10": "#c7fdff",
    "11": "#fff6ba",
    "12": "#d1ffe9",
    "13": "#ffcea2",
    "14": "#ead9fe",
    "15": "#ffc7c6",
    "16": "#abfcfe",
    "18": "#ffacab",
    "19": "#c2ffe1",
    "20": "#f4e9ff",
    "21": "#ffd7b3",
    "22": "#fefadc",
    "23": "#eefadb",
    "26": "#ffcea2",
    "27": "#ead9fe",
    "29": "#abfcfe",
    "30": "#fdc6e6",
    "31": "#e4febd",
    "32": "#d4ecff",
    "33": "#ffacab",
    "34": "#c2ffe1",
    "35": "#f4e9ff",
    "s1": "#c7fdff",
    "s2": "#fff6ba",
    "s3": "#d1ffe9",
    "s4": "#ffcea2",
    "s5": "#ead9fe",
    "s6": "#ffc7c6",
    "s7": "#abfcfe",
    "s8": "#fdc6e6",
    "s9": "#e4febd",
    "s10": "#d4ecff",
    "s22": "#f4e9ff",
    "s23": "#ffd7b3",
    "s24": "#fefadc",
    "s25": "#eefadb",
    "s26": "#c4d1fe",
    "s11": "#fdc6e6",
    "s12": "#e4febd",
    "s13": "#d4ecff",
    "s15": "#c4d1fe",
    "s16": "#fff29c",
    "s17": "#fff0e1",
    "s18": "#fee0f3",
    "s19": "#c7fdff",
    "s20": "#fff6ba",
    "s21": "#d1ffe9",
    "s14": "#ffc7c6"
  };
  public viewDesignMode: string = 'calendar_month';
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public dataType: string = 'event';
  public dataTypeDisplay: string = this.translate.instant('Calendar Items');
  public dataTypeSingleDisplay: string = this.translate.instant('Calendar Item');
  public eventsActions = [];
  public customActions = [
    { value: 'export_data', displayName: 'Export Data' },
    { value: 'export_image', displayName: 'Export Image' },
  ];
  public currentDay: any = undefined;
  public currentWeek: any = undefined;
  public dataSource: any = { events: <EventData[]>[], skelleton: <EventData[]>[] };
  public dataArray: EventData[] = [];
  public dataSkelletonArray: EventData[] = [];

  public startWeekDay: number = 0;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public dataListAll: any[] = [];
  public errorMessage: string = '';
  public anchorDate = new Date();
  public actDateFormat: string = 'EEEE, MMMM dd';
  public actFullDate: string = formatDate(this.anchorDate, this.actDateFormat, 'en');
  public selectedDay = undefined;
  public selectedDayEvents = undefined;
  public selectedDaysAnalyzeEvents = [];
  public filterData = undefined;
  public disableEventBlueprints = true;
  defaultSchedularColors: any = {
    default: 'white',
    selectedDay: '#f6f6f6',
    availability: '#03a8ea9c',
    eventBg: '#f5f5f5',
    eventFont: 'inherit',
    rightEventBorder: '#03a9f4',
    rightEventBg: '#f6f6f6',
    defaultEventBgColor: '',
  }
  public eventsActionsType: any = {
    'occurrence': { displayName: 'Event Occurrence', color: this.defaultSchedularColors.defaultEventBgColor, type: 'default' },
  }
  @Input() doLoadData: boolean = true;
  @Input() personal: boolean = false;
  @Input() dateFormat: string = 'dd-MM-yyyy';
  @Input() momentDateFormat: string = 'DD-MM-YYYY';
  utc = utc;
  @ViewChild('customViewEventTemplate') public customViewEventTemplate: TemplateRef<any>;
  @ViewChild('calendarComp') public calendarComp: CalendarComponent;

  constructor(protected router: Router, protected loaderService: LoaderService,
    protected requestService: RequestService,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar, protected translate: TranslateService,
    protected layoutUtilsService: LayoutUtilsService,
    protected changeDetectorRefs: ChangeDetectorRef, protected activatedRoute: ActivatedRoute,
    protected subheaderService: SubheaderService) { }

  ngOnInit() {
    this.ngOnInitCall();
  }
  ngOnInitCall() {
    // this.isMobile = this.deviceService.isMobile();
    // this.isTablet = this.deviceService.isTablet();
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isCaseManager = this.requestService.getUserRole() === 'edit';
          this.isCaseManagerSuper = this.requestService.getUserRole() === 'superedit';
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.buildSetting();
        }
      })
    );

    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  goTo(path) {
    this.router.navigate([path]);
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = Object.assign({}, data.results);
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          this.eventsActions = [];
          const copiedItem = JSON.parse(JSON.stringify(this.originalTableSetting));
          this.tableSetting = this.getSetting(copiedItem, this.getCustomFilter());
          // console.log('this.metaFieldSetting', this.metaFieldSetting);
          // if (this.isAdmin || this.isCaseManager) {
          this.permissions.canAddEvent = true;
          this.permissions.canEditEvent = true;
          this.permissions.canDeleteEvent = true;
          // }
          this.permissions.canViewEvent = true;
          if (this.doLoadData) {
            this.loadDBData();
          } else {
            this.loaderService.display(false);
            this.loading = false;
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
          this.loaderService.display(false);
        }
      });
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  selectDay(day) {
    if (!this.permissions.canAddEvent) {
      return;
    }
    // console.log('selectDay', day);
    // console.log('this.selectedDay', this.selectedDay);
    // console.log('selectedDayEvents', day.events);
    if (this.selectedDay && this.selectedDay.day == day.day && this.selectedDay.month == day.month && this.selectedDay.year == day.year) {
      this.addEvent({ action: 'occurrence', day: day });
    } else {
      this.selectedDay = day;
      this.selectedDayEvents = this.setUpcoming(day, this.currentDay);
      this.anchorDate = new Date(day.fullDate);
      this.actFullDate = formatDate(this.anchorDate, this.actDateFormat, 'en');
    }
    // this.addEvent({ action: 'occurrence', day: day });
  }

  selectEvent(event) {
    // console.log('selectEvent', event);
    let momentUntil = moment().utc();
    let enddate = moment(event.event.enddate).utc();
    if (!this.permissions.canViewEvent) {
      return;
    }
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.requestService.getSingleData(this.dataType, event.event._id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.continueSelectEvent({ event: data.results });
        }
        this.loaderService.display(false);
        this.loading = false;
      });
    }
  }
  continueSelectEvent(event) {
    // console.log('selectEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    let canEditEvent = this.permissions.canEditEvent;
    let canDeleteEvent = this.permissions.canDeleteEvent;
    let canCreateEvent = false;
    let canOpen = false;

    // if (momentUntil.diff(enddate, 'minutes') > 0 && !this.isAdmin) {
    //   canEditEvent = false;
    //   canDeleteEvent = false;
    //   canCreateEvent = false;
    // }
    if (event.event.createdBy !== this.selectedUser._id) {
      canDeleteEvent = false;
      canEditEvent = false;
    }
    let currentEvent = JSON.parse(JSON.stringify(event.event));
    // let attendeesHost = undefined;
    // let attendeesDataList = []

    // if (currentEvent.attendees && currentEvent.attendees.length > 0) {
    //   for (let itm of currentEvent.attendees) {
    //     if (itm.host) {
    //       attendeesHost = itm;
    //     } else {
    //       attendeesDataList.push(itm);
    //     }
    //   }
    // }
    // currentEvent['attendeesHost'] = attendeesHost;
    // currentEvent['attendeesDataList'] = attendeesDataList;
    const dialogRef = this.dialog.open(CustomModalViewEventDialogComponent, {
      width: '800px',
      data: {
        title: this.translate.instant('Calendar Item'),
        data: currentEvent,
        fields: tableSetting.fields,
        canEdit: canEditEvent,
        canDelete: canDeleteEvent,
        canCreate: canCreateEvent,
        canOpen: canOpen,
        dateFormat: this.dateFormat,
        contentTemplate: this.customViewEventTemplate,
        showAddToPersonalCalendar: true,
        extraButtons: [
          // { action: 'open_add_to_calendar', name: 'Add to my personal calendar', title: 'Add to my personal calendar' }
          { action: 'open_files', name: 'View and Upload Files', title: 'View and Upload Files', target: 'self' },
          { action: 'send_email', name: 'Send by Email', title: 'Send by Email', target: 'self' }
        ]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'edit') {
          this.editEvent(event.event);
        } else if (result === 'delete') {
          this.deleteEvent(event.event);
        } else if (result === 'open_add_to_calendar') {
          // do nothing
          console.log(result);
        } else if (result === 'open_files') {
          // do nothing
          // const dialogRef = this.dialog.open(ModalFilesEventDialogComponent, {
          //   width: '1600px',
          //   disableClose: false,
          //   autoFocus: false,
          //   data: {
          //     title: this.translate.instant('Select files or upload new ones'),
          //     multipleSelection: true,
          //     maxFiles: 5,
          //     targetId: currentEvent._id,
          //     dataType: 'event',
          //     dataTypeDisplay: 'File',
          //     data: [],
          //   }
          // });
          // dialogRef.afterClosed().subscribe(result => {
          //   if (result) {
          //     console.log('nothing')
          //   }
          // });
        } else if (result === 'create') {
          // do nothing
        }
      }
    });

  }
  deleteEvent(event) {
    // if (!this.permissions.canDeleteEvent) {
    //   return;
    // }
    this.confirmDelete(event);
  }
  editEvent(event) {
    // console.log('editEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    // console.log('tableSetting', tableSetting);
    let eventTypeName = this.translate.instant('Calendar Item');
    let title = this.translate.instant('Edit') + ' ' + eventTypeName;
    tableSetting['customSettings']['attendees'] = {
      visible: false
    };
    tableSetting['customSettings']['color'] = {
      visible: true
    };
    tableSetting['customSettings']['startdate'] = {
      visible: false
    };
    tableSetting['customSettings']['enddate'] = {
      visible: false
    };
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Calendar Item'),
        title: title,
        data: event,
        startWeekDay: this.startWeekDay,
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmEdit(result.data, eventTypeName);
      }
    });
  }

  userConfirmEdit(data, eventTypeName) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = false;
    alertSetting['confirmText'] = this.translate.instant('Yes');
    alertSetting['declineText'] = this.translate.instant('No');
    // alertSetting['timerEvent'] = 120;

    let activityDialog = this.layoutUtilsService.alertActionElement('Confirmation', 'This event has already started. Are you sure you want to continue?', alertSetting);
    activityDialog.afterClosed().subscribe(res => {
      if (res) {
        // if(res.action === 'declineText'){
        //   // this.subscribeActivity(); // do nothing
        // }else
        if (res.action === 'confirmText') {
          activityDialog.close();
          this.confirmEdit(data, eventTypeName);
        } else {
          // do nothing
        }
      }
    });
  }
  dayShift(event) {
    // console.log('event', event);
    if (this.currentWeek !== event.currentWeek) {
      this.currentWeek = event.currentWeek;
    }
    if (!this.currentDay || (event.currentDay && this.currentDay && (this.currentDay.month != event.currentDay.month || this.currentDay.year != event.currentDay.year))) {
      if (event.status === 'init' || event.status === 'change') {
        this.currentDay = event.currentDay;
      }
      if (event.status === 'change') {
        this.loadDBData();
      }
    }
    // console.log('dayShift', event);
    if (event.selectedDay) {
      let day = JSON.parse(JSON.stringify(event.selectedDay));
      let currentDay = JSON.parse(JSON.stringify(event.currentDay));
      this.selectedDay = day;
      if (day.fullDate) {
        setTimeout(() => {
          this.selectedDayEvents = this.setUpcoming(day, currentDay);
          this.anchorDate = new Date(day.fullDate);
          this.actFullDate = formatDate(this.anchorDate, this.actDateFormat, 'en');
        }, 300);
      }
    }
  }
  setUpcoming(day, currentDay) {
    let events = day.events;
    if (day.day === currentDay.day && day.month === currentDay.month && day.year === currentDay.year) {
      let currentDate = moment().utc();
      let nearestDate = events.find(date => {
        return ((currentDate.diff(moment(date.startdate).utc(), 'minutes') <= 0) || (currentDate.diff(moment(date.startdate).utc(), 'minutes') > 0 && currentDate.diff(moment(date.enddate).utc(), 'minutes') <= 0))
      });
      if (nearestDate) {
        events = events.map((itm) => {
          if (nearestDate._id === itm._id) {
            itm['upcoming'] = true;
          }
          return itm;
        })
      }
    }
    return events;
  }
  actionEvent(action) {
    console.log('actionEvent', action);
    if (action === 'export_data') {
      this.exportData();
    } else if (action === 'export_image') {
      this.screenshot('#targetCalendarElement');
    }
  }
  addEvent(event) {
    // console.log('addEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    let action = event.action;
    let eventTypeName = this.translate.instant('Calendar Item');
    let title = 'New ' + eventTypeName;

    tableSetting['customSettings']['attendees'] = {
      visible: false
    };
    tableSetting['customSettings']['partno'] = {
      value: 1
    };
    tableSetting['customSettings']['startdate'] = {
      visible: false
    };
    tableSetting['customSettings']['enddate'] = {
      visible: false
    };
    tableSetting['customSettings']['color'] = {
      displayName: 'Color'
    };
    if (!event.hasOwnProperty('day')) {
      let selectedDay = JSON.parse(JSON.stringify(this.selectedDay));
      delete selectedDay.hour;
      event['day'] = selectedDay;
    }
    if (event.hasOwnProperty('day')) {
      let val = new Date();
      let valEnd = new Date();
      if (event.day.hasOwnProperty('hour')) {
        val = new Date(event.day.year, event.day.month, event.day.day, event.day.hour.split(':')[0], event.day.hour.split(':')[1]);
        valEnd = new Date(event.day.year, event.day.month, event.day.day, event.day.hour.split(':')[0], event.day.hour.split(':')[1]);
        valEnd.setMinutes(valEnd.getMinutes() + 30);
      } else {
        var currentMinutes = val.getMinutes();
        var roundedMinutes = Math.ceil(currentMinutes / 30) * 30;
        val = new Date(event.day.year, event.day.month, event.day.day, val.getHours(), roundedMinutes);
        valEnd = new Date(event.day.year, event.day.month, event.day.day, valEnd.getHours(), roundedMinutes);
        valEnd.setHours(valEnd.getHours() + 1);
      }
      tableSetting['customSettings']['startdate']['value'] = val;
      tableSetting['customSettings']['enddate']['value'] = valEnd;
    }
    tableSetting['customSettings']['color'] = {
      visible: true,
      value: ''
    };

    tableSetting['customSettings']['eventType'] = {
      visible: false,
      value: action
    };
    // console.log('tableSetting', tableSetting);
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Calendar Item'),
        title: title,
        data: {},
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCreate(result.data, eventTypeName);
      }
    });
  }

  private getSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      foreign_key: {
        visible: false,
        value: this.requestService.orgId
      },
      createdBy: {
        visible: false,
        value: this.selectedUser._id
      },
      project: {
        visible: false,
        value: environment.projectName
      }
    };
    return tableSetting;
  }
  getUserAsAttendee() {
    return [{ _id: this.selectedUser._id, name: this.selectedUser.name, email: this.selectedUser.email, host: true }];
  }
  private getCustomFilter() {
    let filters = {
      "foreign_key": { "$eq": this.requestService.orgId },
      'project': { '$eq': environment.projectName }
    };
    // if (this.selectedUser.email !== 'amerghalayini@interactivelife.com') {
    filters["$or"] = this.commonFilter();
    // }
    return filters;
  }
  commonFilter() {
    return [
      {
        "attendees": { "$size": 0 },
        "createdBy": { "$eq": this.selectedUser._id }
      },
      {
        "attendees._id": this.selectedUser._id,
        "createdBy": { "$ne": this.selectedUser._id }
      }
    ]
  }
  getFullDay(currentDay) {
    return new Date(this.currentDay.year, this.currentDay.month, 1);
  }
  loadDBData(focusDay = undefined, goToDayMonth = false, passSelectedDay = false) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);

      // let mom = moment;
      let currentStartDate = moment();
      let currentEndDate = moment();
      if (this.currentDay) {
        let fullDate = this.getFullDay(this.currentDay);
        currentStartDate = moment(fullDate);
        currentEndDate = moment(fullDate);
      }
      let startFilterBreakdown = currentStartDate.clone().utc().subtract(this.maxRangeBreakdown, 'months');
      let endFilterBreakdown = currentEndDate.clone().utc().add(this.maxRangeBreakdown, 'months');
      this.startfilterRange = startFilterBreakdown.toDate();
      this.endfilterRange = endFilterBreakdown.toDate();

      // let termConfiguration = this.termConfiguration();
      let startFilter = currentStartDate.clone().utc().subtract(this.maxRange, 'days');
      let endFilter = currentEndDate.clone().utc().add(this.maxRange, 'days');
      let filters = {
        'foreign_key': { '$eq': this.requestService.orgId },
        'project': { '$eq': environment.projectName },
        'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
        'enddate': { '$lte': endFilter.format('YYYY-MM-DDTHH:mm') }
      }
      // if (this.selectedUser.email !== 'amerghalayini@interactivelife.com') {
      filters["$or"] = this.commonFilter();

      // }
      let filterObj = { page: 1, perpage: 10000000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: ['name', 'type', 'eventType', 'attendees', 'color', 'isRecurrence', 'recurrence', 'startdate', 'enddate', 'category', 'subcategory', 'allday', 'meetingType', 'location'], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          //console.log(data);
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            this.loaderService.display(false);
            this.loading = false;
          }
          if (data) {
            let dataList = data.results || [];
            this.dataListAll = dataList;
            this.setUpData(dataList, [], focusDay, goToDayMonth, passSelectedDay);
            let currentStartDateStart = moment().startOf('day');
            let currentEndDateStart = moment().endOf('day');
            // let startFilter = currentStartDateStart.subtract(this.maxAnalysisRange, 'days');
            // let endFilter = currentEndDateStart.add(this.maxAnalysisRange, 'days');
            let startFilter = currentStartDateStart;
            let endFilter = currentEndDateStart;
            this.prepareDataAnalysis(dataList, startFilter, endFilter);
            this.loaderService.display(false);
            this.loading = false;
          }
        });
    }
  }
  loadDBBreakdownData(startFilter, endFilter) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);

      let filters = {
        'foreign_key': { '$eq': this.requestService.orgId },
        'project': { '$eq': environment.projectName },
        'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
        'enddate': { '$lte': endFilter.format('YYYY-MM-DDTHH:mm') }
      }
      // if (this.selectedUser.email !== 'amerghalayini@interactivelife.com') {
      filters["$or"] = this.commonFilter();

      // }
      let filterObj = { page: 1, perpage: 10000000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: ['startdate', 'enddate', 'category', 'subcategory'], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          //console.log(data);
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            this.loaderService.display(false);
            this.loading = false;
          }
          if (data) {
            let dataList = data.results || [];
            this.prepareDataAnalysis(dataList, startFilter, endFilter);
            this.loaderService.display(false);
            this.loading = false;
          }
        });
    }
  }
  onMenuOpened(): void {
    this.menuIsOpen = true;
  }

  onMenuClosed(): void {
    this.menuIsOpen = false;
    let currentStartDate = moment(this.selectedStartDate).startOf('day');
    let currentEndDate = moment(this.selectedEndDate).endOf('day');
    this.loadDBBreakdownData(currentStartDate, currentEndDate)
    // this.prepareDataAnalysis(this.dataListAll, currentStartDate, currentEndDate);
  }
  startMenuClose(event: Event): void {
    // Prevent the click event from propagating up to the MatMenu
    event.stopPropagation();
  }
  endMenuClose(event: Event): void {
    // Prevent the click event from propagating up to the MatMenu
    event.stopPropagation();
  }
  startMenuChange(event: Event): void {
    if (this.selectedEndDate >= event) {
      this.selectedStartDate = event;
    } else {
      this.layoutUtilsService.showNotification(this.translate.instant('Start Date should be less than End Date'), this.translate.instant('Dismiss'));
    }
  }
  endMenuChange(event: Event): void {
    if (this.selectedStartDate <= event) {
      this.selectedEndDate = event;
    } else {
      this.layoutUtilsService.showNotification(this.translate.instant('End Date should be greater than Start Date'), this.translate.instant('Dismiss'));
    }
  }
  public getAnalyzeCategories(mainCategories) {
    let result = [];
    for (let category of mainCategories) {
      let subcategoriesList = getDataFromListContains(this.metaFieldSetting['subcategory'].enum, [category.value], 'category');
      if (subcategoriesList.length > 0) {
        result = result.concat(subcategoriesList);
      } else {
        category['parent'] = true;
        result.push(category);
      }
    }
    return result;
  }
  prepareDataAnalysis(events, startFilter, endFilter) {
    this.selectedStartDate = startFilter.toDate();
    this.selectedEndDate = endFilter.toDate();
    let myWorkingEvents = events.filter((itm) => {
      let startdate = moment(itm.startdate).utc();
      let enddate = moment(itm.enddate).utc();
      if ((startFilter.diff(startdate, 'minutes') <= 0 && startdate.diff(endFilter, 'minutes') <= 0) || (startFilter.diff(enddate, 'minutes') <= 0 && enddate.diff(endFilter, 'minutes') <= 0)) {
        return true;
      } else {
        return false;
      }
    });
    let selectedDaysAnalyzeEvents = this.getAnalyzeCategories(this.metaFieldSetting['category'].enum);
    let analyzedEvents = {}
    for (let itm of myWorkingEvents) {
      let categoryValue = getItemFromListContains(this.metaFieldSetting['category'].enum, itm.category, 'value');
      let subcategoryValue = getItemFromListContains(this.metaFieldSetting['subcategory'].enum, itm.subcategory, 'value');
      let indexValue = undefined;
      if (subcategoryValue && !subcategoryValue.others) {
        indexValue = 's' + subcategoryValue.value;
      } else if (categoryValue && !categoryValue['others']) {
        indexValue = categoryValue.value + '';
      }
      if (indexValue) {
        if (!analyzedEvents.hasOwnProperty(indexValue)) {
          analyzedEvents[indexValue] = 0;
        }
        let startdate = moment(itm.startdate).utc();
        let enddate = moment(itm.enddate).utc();
        let totalMinutes = enddate.diff(startdate, 'minutes');
        analyzedEvents[indexValue] = analyzedEvents[indexValue] + totalMinutes;
      }
    }
    for (let itm of Object.keys(analyzedEvents)) {
      let totalMinutes = analyzedEvents[itm];
      let hours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      analyzedEvents[itm] = { hours: hours, minutes: remainingMinutes };
    }
    selectedDaysAnalyzeEvents = selectedDaysAnalyzeEvents.map((itm) => {

      let indexValue = itm.value + '';
      if (!itm.parent) {
        indexValue = 's' + itm.value;
      }
      itm['total'] = analyzedEvents.hasOwnProperty(indexValue) ? analyzedEvents[indexValue]['hours'] : 0;
      itm['totalMin'] = analyzedEvents.hasOwnProperty(indexValue) ? analyzedEvents[indexValue]['minutes'] : 0;
      itm['unit'] = itm['total'] < 2 ? 'hr' : 'hrs';
      itm['unitMin'] = itm['totalMin'] < 2 ? 'min' : 'mins';
      itm['color'] = this.lightColors[indexValue] ? this.lightColors[indexValue] : this.defaultSchedularColors.rightEventBg;
      return itm;
    })
    this.selectedDaysAnalyzeEvents = selectedDaysAnalyzeEvents;
  }
  setUpData(events, skelleton, focusDay = undefined, goToDayMonth = false, passSelectedDay = false) {
    this.dataArray = events.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = this.defaultSchedularColors.defaultEventBgColor;
        }
      }
      return itm;
    });
    this.dataSource = { events: this.dataArray, skelleton: this.dataSkelletonArray, focusDay: focusDay, goToDayMonth: goToDayMonth, passSelectedDay: passSelectedDay };
  }
  public confirmCreate(dataToCreate, eventTypeName) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToCreate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let returnResult = data.results;
          this.loadDBData(dataToCreate, false, true);
          this.layoutUtilsService.showNotification(eventTypeName + ' ' + this.translate.instant('created successfully'), this.translate.instant('Dismiss'));
        } else {
          this.loaderService.display(false);
        }
      });
    }
  }
  public confirmEdit(dataToUpdate, eventTypeName) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToUpdate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData(dataToUpdate, false, true);
          this.layoutUtilsService.showNotification(eventTypeName + ' ' + this.translate.instant('edited successfully'), this.translate.instant('Dismiss'));
        } else {
          this.loaderService.display(false);
        }
      });
    }
  }
  confirmDelete(event) {
    if (event.isRecurrence) {
      let mainMsg = 'You are deleting a ' + this.dataTypeSingleDisplay.toLowerCase() + ' with recurrence. How do you want to proceed?';
      let confirmBtn = 'Keeping past and current ' + this.dataTypeDisplay.toLowerCase() + ' and deleting future ones';
      let declineBtn = 'Deleting all ' + this.dataTypeDisplay.toLowerCase() + ' existing and current';
      this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
        if (callbackStatus !== undefined) {
          let data = { _id: event._id, eventType: event.eventType }
          if (callbackStatus === true) {
            data['overWrite'] = '4';
          } else if (callbackStatus === false) {
            data['overWrite'] = '5';
          }
          this.confirmRealDelete(data);
        }
      });
    } else {
      this.delete(event);
    }
  }
  public delete(event: any) {
    let id = event._id;
    if (!this.loading) {
      let dataTypeDisplay = this.dataTypeSingleDisplay;
      const _title: string = dataTypeDisplay + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(event);
      });
    }
  }
  public confirmRealDelete(data) {
    let dataTypeDisplay = this.dataTypeSingleDisplay;
    const _deleteMessage = dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData();
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public realDelete(event: any) {
    let id = event._id;
    let dataTypeDisplay = this.dataTypeSingleDisplay;
    const _deleteMessage = dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          if (error === 'This event is live right now. You will be able to delete it once the session ends.') {
            this.layoutUtilsService.alertElement('Alert', error);
          } else {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
          }
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.loadDBData();
        }
      });
    }
  }
  private handleConfirmChangeModal(mainMsg: string, confirmBtn: string, declineBtn: string, callback: (dataResponse: boolean) => void, smallMsg: string = undefined) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = true;
    alertSetting['smallMsg'] = smallMsg;
    alertSetting['confirmText'] = confirmBtn;
    alertSetting['declineText'] = declineBtn;

    const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Confirm Changes'), this.translate.instant(mainMsg), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          callback(true);
        } else if (result.action === 'declineText') {
          callback(false);
        }
      } else {
        callback(undefined);
      }
    });
  }
  getOrdinalSuffix(date: Date): string {
    let day = date.getDate();
    if (day >= 11 && day <= 13) {
      return 'th';
    }

    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  screenshot(targetDiv = '#calendarContainer') {
    // Select the element that you want to capture
    let captureElement: any = document.querySelector(targetDiv);

    // Call the html2canvas function and pass the element as an argument
    html2canvas(captureElement).then((canvas) => {
      // Get the image data as a base64-encoded string
      const imageData = canvas.toDataURL("image/png");

      // Do something with the image data, such as saving it as a file or sending it to a server
      // For example, you can create an anchor element and trigger a download action
      const link = document.createElement("a");
      let actFullDate = formatDate(this.anchorDate, 'yyyy', 'en');
      let fileName = sanitizeFilename(actFullDate + "_my_calendar.png");
      link.setAttribute("download", fileName);
      link.setAttribute("href", imageData);
      link.click();
    });
  }
  exportData() {
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    const dialogRef = this.dialog.open(ModalSchedulerExportDialogComponent, {
      width: '850px',
      data: {
        title: this.translate.instant('Export Data'),
        users: [this.selectedUser],
        clients: false,
        viewMode: this.calendarComp.viewMode,
        selectedClient: this.selectedUser._id,
        metaFieldSetting: this.metaFieldSetting,
        momentDateFormat: this.momentDateFormat
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }
}
