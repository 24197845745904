<!-- <select id="{{dataType}}" class="form-control" [disabled]="loading" (change)="setAttribute($event.target.value)">
  <option value="" [selected]="value === undefined">{{placeholder}}</option>
  <option value="{{item._id}}" *ngFor="let item of selectData" [selected]="value === item._id">{{item.name}}</option>
</select> -->
<form #dataForm="ngForm" (ngSubmit)="onSubmit()" *ngIf="apiTarget !== 'search' && apiTarget !== 'searchObject'">
  <div *ngIf="!hideBecauseOne">
    <div>
      <mat-form-field>
        <input [style.visibility]="'hidden'" matInput name="{{itemName}}" [(ngModel)]="value" [required]="required"
          [errorStateMatcher]="esMatcher">
        <mat-label>{{placeholder | translate}}</mat-label>
        <mat-select ng-model="itemName" [required]="required" (selectionChange)="setAttribute($event)" [value]="value"
          [disabled]="conditionalDisable">
          <mat-option value="">Select {{placeholder | translate}}</mat-option>
          <mat-option *ngFor="let itm of selectData" [value]="itm._id">
            <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
            <span *ngIf="showEmail">({{itm['email']}}) </span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="required && (value === undefined || value === '')">
          {{placeholder | translate}} is required.
        </mat-error>
      </mat-form-field>
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
    <!-- <div *ngIf="apiTarget === 'search' || apiTarget === 'searchObject'" class="searchClass">
      <ng-select #ngselect class="custom" ng-model="itemName" name="{{itemName}}" [items]="filteredData | async"
        bindLabel="text" autofocus bindValue="_id" [loading]="loading" [clearable]="clearable"
        (search)="termSearch($event)" (clear)="termSearch('')" (change)="setAttribute($event)" [(ngModel)]="value"
        [disabled]="conditionalDisable" (blur)="onBlur(ngselect)" placeholder="{{placeholder | translate}}">
      </ng-select>
      <div class="my-error mat-error mat-form-field-subscript-wrapper"
        *ngIf="required && (value === undefined || value.length === 0)">
        {{placeholder | translate}} is required.
      </div>
    </div> -->
  </div>
</form>
<div *ngIf="showIt && (apiTarget === 'search' || apiTarget === 'searchObject')" class="searchClass">
  <div *ngIf="!hideBecauseOne">
    <ng-select #ngselect class="custom" ng-model="itemName" name="{{itemName}}" [items]="filteredData | async"
      bindLabel="text" bindValue="_id" [loading]="loading" [clearable]="clearable" (search)="termSearch($event)"
      [dropdownPosition]="dropdownPosition" (clear)="setAttribute({_id:null, name: ''});termSearch('')"
      (change)="setAttribute($event)" [(ngModel)]="value" [disabled]="conditionalDisable" (blur)="onBlur(ngselect)"
      placeholder="{{placeholder | translate}}">
    </ng-select>
    <div class="my-error mat-error mat-form-field-subscript-wrapper"
      *ngIf="required && (value === undefined || value.length === 0)">
      {{placeholder | translate}} is required.
    </div>
  </div>
</div>