<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
  <div fxFlex="75">
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
      <div fxFlex>
        <div class="title">
          <mat-form-field>
            <mat-label>{{'Select Clients' | translate}}</mat-label>
            <mat-select name="select-clients" (selectionChange)="setSelectedClients($event.value)"
              [(ngModel)]="selectedClient">
              <mat-option value="all">All Clients</mat-option>
              <ng-container *ngFor="let itm of userClients">
                <mat-option [value]="itm._id" title="{{itm.email}}">{{itm.name}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex fxLayoutAlign="end center">
        <button mat-icon-button [matMenuTriggerFor]="customActionsMenu" title="{{'Export' | translate}}">
          <img src="/assets/images/export_notes.svg" />
        </button>
        <mat-menu #customActionsMenu="matMenu">
          <button mat-menu-item (click)="actionEvent(itm.value)"
            *ngFor="let itm of customActions">{{itm.displayName}}</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
  <div fxFlex="75">
    <div style="height: 100%; width: 100%;border: 1px solid lightgray;">
      <app-calendar *ngIf="dataArray || dataSkelletonArray" #calendarComp [startWeekDay]="startWeekDay"
        [isMobile]="isMobile" [isTablet]="isTablet" [dataTypeDisplay]="dataTypeDisplay"
        [defaultColors]="defaultSchedularColors" [showAddButton]="true" [language]="'en'" [dataSource]="dataSource"
        [eventsActions]="eventsActions" [contentDataTemplate]="customContentDataTemplate"
        [canAddEvent]="permissions.canAddEvent" [bodyHeight]="'calc(100vh - 200px)'"
        [contentTemplate]="customEventTemplate" (dayEvents)="selectDay($event)" (dayEvent)="selectEvent($event)"
        (newEvent)="addEvent($event)" (dayShift)="dayShift($event)" [selectedFilter]="selectedFilter"
        [viewDesignMode]="viewDesignMode" [showSelectedDay]="true" [showDesignModeOptions]="false" [showToolTip]="false"
        [eventsSelectable]="false" [eventsAvlbSelectable]="true" [enableAvailability]="selectedClient === 'all'"
        [availabilityBtn]="'Show common availabilities'" [newDesign]="true"></app-calendar>
    </div>
  </div>
  <div fxFlex="25">
    <div fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutGap="5px" class="rightColumn">
      <div fxFlex>
        <div style="height: 100%;width: 100%;border: 1px solid lightgray;">
          <div fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutGap="5px">
            <div fxFlex class="header-date">
              <div class="titleDate">
                {{actFullDate}}
              </div>
            </div>
            <div fxFlex class="listDate">
              <div class="eventList" style="width: calc(100% - 10px);"
                *ngIf="selectedDayEvents && selectedDayEvents.length > 0">
                <div class="eventListItem" *ngFor="let event of selectedDayEvents;let index = index"
                  [ngStyle]="{ 'border-color': event.color !== ''? event.color : this.defaultSchedularColors.rightEventBorder, backgroundColor: event.upcoming? defaultSchedularColors.rightEventBg : ''}"
                  (click)="selectEvent({event})" customToolTip [contentTemplate]="customEventTemplate"
                  [contentTemplateData]="event">
                  <div class="eventItem" fxLayout="row" fxFlex>
                    <div fxFlex="90">
                      <div class="l-time">{{event.startdate | date: 'h:mm aa'}} <span
                          style="font-weight: 600;font-size: 1.1rem;margin-left:5px">{{event.name| truncate :
                          ['100','...']}}</span>
                      </div>
                      <div class="l-location">
                        <span *ngIf="event.meetingType === '1'">
                          <span>
                            <app-enum-view [enumValue]="event.meetingType"
                              [enumList]="metaFieldSetting['meetingType'].enum"></app-enum-view>
                          </span>
                        </span>
                        <span *ngIf="event.meetingType === '2'">
                          <span *ngIf="event.location !== ''">
                            {{event.location | truncate: ['50','...']}}
                          </span>
                          <span *ngIf="event.location === ''">
                            <app-enum-view [enumValue]="event.meetingType"
                              [enumList]="metaFieldSetting['meetingType'].enum"></app-enum-view>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div fxFlex="10" *ngIf="event.upcoming" align="right">
                      <div class="l-highlight">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex>
        <div style="height: 100%;width: 100%;border: 1px solid lightgray;" *ngIf="selectedStartDate && selectedEndDate">
          <div fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutGap="5px">
            <div fxFlex class="header-date">
              <div class="titleDate">
                {{selectedStartDate | date: 'MMM d'}}{{selectedStartDate | ordinalDate}} - {{selectedEndDate | date:
                'MMM d'}}{{selectedEndDate | ordinalDate}}
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" (opened)="onMenuOpened()" (closed)="onMenuClosed()">
                  <div class="calendarContainer">
                    <div class="calendarTitle">Start Date</div>
                    <mat-calendar [selected]="selectedStartDate" [startAt]="selectedStartDate"
                      (click)="startMenuClose($event)" [maxDate]="endfilterRange" [minDate]="startfilterRange"
                      (selectedChange)="startMenuChange($event)">
                    </mat-calendar>
                    <div class="calendarTitle">End Date</div>
                    <mat-calendar [selected]="selectedEndDate" [startAt]="selectedEndDate"
                      (click)="endMenuClose($event)" (selectedChange)="endMenuChange($event)" [maxDate]="endfilterRange"
                      [minDate]="startfilterRange"></mat-calendar>
                  </div>
                </mat-menu>
              </div>
              <div class="subTitleDate">
                Task Breakdown
              </div>
            </div>
            <div fxFlex class="listDateAnalysis">
              <mat-list style="width: calc(100% - 10px);">
                <ng-container *ngFor="let event of selectedDaysAnalyzeEvents;let index = index">
                  <mat-list-item *ngIf="event.total > 0 || event.totalMin > 0"
                    style="cursor: default;border-color: none"
                    [ngStyle]="{ 'backgroundColor': event.color? event.color : '#03a9f4' }">
                    <div class="eventItem">
                      <div class="l-title"><span *ngIf="event.total > 0">{{event.total }}
                          {{event['unit']}}</span>&nbsp;<span *ngIf="event.totalMin > 0">{{event.totalMin }}
                          {{event['unitMin']}}</span>&nbsp;{{event.displayName| truncate :
                        ['50','...']}}</div>
                    </div>
                  </mat-list-item>
                </ng-container>
              </mat-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #customEventTemplate let-contentTemplateData="contentTemplateData">
  <div class="eventOverlay" *ngIf="contentTemplateData">
    <div class="eventOverlayTitle" *ngIf="contentTemplateData.name">
      <div>{{contentTemplateData.name}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate && contentTemplateData.enddate">
      <div style="margin: 0px;">{{contentTemplateData.startdate | date: 'h:mm aa'}} -
        {{contentTemplateData.enddate | date: 'h:mm aa'}}</div>
      <div style="max-width:30vw" *ngIf="contentTemplateData.category && contentTemplateData.category !== ''">
        <app-enum-view [enumValue]="contentTemplateData.category" [enumList]="metaFieldSetting['category'].enum">
        </app-enum-view>
      </div>
      <!-- <div style="max-width:30vw" *ngIf="contentTemplateData.description && contentTemplateData.description !== ''">
        {{ (contentTemplateData.description.length>1000)? (contentTemplateData.description |
        slice:0:1000)+'...':(contentTemplateData.description) }}
      </div> -->
    </div>
  </div>
</ng-template>
<ng-template #customViewEventTemplate let-contentTemplateData="contentTemplateData">
  <mat-list class="eventClass">
    <mat-list-item *ngIf="contentTemplateData.meetingType && contentTemplateData.meetingType !== ''">
      <div class="eventDescription eventMeetingType">
        <app-enum-view [enumValue]="contentTemplateData.meetingType" [enumList]="metaFieldSetting['meetingType'].enum">
        </app-enum-view>
      </div>
    </mat-list-item>
    <mat-list-item
      *ngIf="contentTemplateData.meetingType === '2' && contentTemplateData.location && contentTemplateData.location !== ''">
      <div class="eventDescription eventLocation">
        <span>{{metaFieldSetting['location'].displayName | translate}}: </span>
        <span>{{contentTemplateData.location}}</span>
      </div>
    </mat-list-item>
    <mat-list-item
      *ngIf="contentTemplateData.meetingType === '1' && contentTemplateData.meetingurl && contentTemplateData.meetingurl !== ''">
      <div class="eventDescription eventLocation">
        <span>{{metaFieldSetting['meetingurl'].displayName | translate}}: </span>
        <span><a class="itm_email" target="_blank"
            href="{{contentTemplateData.meetingurl}}">{{contentTemplateData.meetingurl}}</a></span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="eventDescription eventenddate">
        <span>{{'Date'| translate}}: </span> {{contentTemplateData.startdate | date:
        dateFormat}}
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="eventDescription eventenddate">
        <span>{{'Start'| translate}}: </span> {{contentTemplateData.startdate | date:
        'h:mm aa'}}
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="eventDescription eventenddate">
        <span>{{'End'| translate}}: </span> {{contentTemplateData.enddate | date:
        'h:mm aa'}}
      </div>
    </mat-list-item>
    <mat-list-item
      *ngIf="contentTemplateData.attendees && contentTemplateData.attendees !== ''&& contentTemplateData.attendees.length > 0">
      <div class="eventDescription eventMeetingType">
        <span
          *ngIf="contentTemplateData.attendees[0].type !== 'case-manager'">{{metaFieldSetting['attendees'].displayName |
          translate}}: </span>
        <span *ngIf="contentTemplateData.attendees[0].type === 'case-manager'">{{'Case Manager' | translate}}: </span>
        <span *ngFor="let itm of contentTemplateData.attendees;let index = index">{{itm.name}}<span
            *ngIf="index < contentTemplateData.attendees.length - 1">,
          </span></span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.category && contentTemplateData.category !== ''">
      <div class="eventDescription eventMeetingType">
        <span>{{metaFieldSetting['category'].displayName | translate}}: </span>
        <app-enum-view [enumValue]="contentTemplateData.category" [enumList]="metaFieldSetting['category'].enum">
        </app-enum-view>
        <span *ngIf="contentTemplateData.category === '4' && contentTemplateData.others">
          ({{contentTemplateData.others}})</span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.subcategory && contentTemplateData.subcategory !== ''">
      <div class="eventDescription eventMeetingType">
        <span>{{metaFieldSetting['subcategory'].displayName | translate}}: </span>
        <app-enum-view [enumValue]="contentTemplateData.subcategory" [enumList]="metaFieldSetting['subcategory'].enum">
        </app-enum-view>
        <span *ngIf="contentTemplateData.subcategory === '23' && contentTemplateData.others">
          ({{contentTemplateData.others}})</span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.facility && contentTemplateData.facility !== ''">
      <div class="eventDescription eventMeetingType">
        <span>{{metaFieldSetting['facility'].displayName | translate}}: </span>
        <app-enum-view [enumValue]="contentTemplateData.facility" [enumList]="metaFieldSetting['facility'].enum">
        </app-enum-view>
      </div>
    </mat-list-item>
    <mat-list-item
      *ngIf="contentTemplateData.partno && contentTemplateData.partno !== '' && contentTemplateData.partno > 0">
      <div class="eventDescription">
        <span>{{metaFieldSetting['partno'].displayName | translate}}: </span>
        <span>{{contentTemplateData.partno}}</span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.isRecurrence && contentTemplateData.humantext">
      <div class="eventDescription eventRecurrent">
        <span>Repeats</span> {{contentTemplateData.humantext}}
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.description && contentTemplateData.description !== ''">
      <div class="eventDescription">
        <span>{{metaFieldSetting['description'].displayName | translate}}: </span>
        <span>{{contentTemplateData.description}}</span>
      </div>
    </mat-list-item>
  </mat-list>
</ng-template>
<ng-template #attendeeTypes let-contentTemplateData="contentTemplateData">
  <div *ngIf="contentTemplateData.attendeesDataList && contentTemplateData.length > 0">
    {{ 'Clients'
    | translate}}: <span *ngFor="let ett of contentTemplateData.attendeesDataList;let index=index">{{ett.name}}<span
        *ngIf="index < contentTemplateData.attendeesDataList.length - 2">,
      </span>
      <span *ngIf="index === contentTemplateData.attendeesDataList.length - 2"> and
      </span> </span>
  </div>
</ng-template>
<ng-template #openToAllData let-contentTemplateData="contentTemplateData">
  <div class="eventDescription eventOpentoall">
    <span>
      This event is open for
      <ng-container
        *ngIf="contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0">
        {{peopleType['attendee']
        | translate}}s</ng-container>
    </span>
  </div>
</ng-template>
<ng-template #customContentDataTemplate let-contentTemplateData="contentTemplateData">
  <span class="day-items">
    <mat-icon
      [ngStyle]="{ backgroundColor: contentTemplateData.event.color!== ''? contentTemplateData.event.color : defaultSchedularColors.eventBg, color: contentTemplateData.event.color!== ''? defaultSchedularColors.eventFont : defaultSchedularColors.eventBg === 'unset' ? 'inherit' : defaultSchedularColors.eventFont }"
      class="eventIcon" *ngIf="contentTemplateData.event.isRecurrence" title="{{'Repeating calendar item'}}">
      cached
    </mat-icon>
    <span>{{contentTemplateData.event.startdate | date:
      'h:mm aa'}} - {{contentTemplateData.event.enddate | date:
      'h:mm aa'}}
    </span>
    <span style="font-weight: 500;">
      {{contentTemplateData.event.name | truncate : ['50','...'] }}
    </span>
  </span>

</ng-template>