import { environment } from '../environments/environment';
export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'My Calendar',
					root: true,
					icon: 'list',
					page: '/dashboard',
					bullet: 'dot',
				},
				{
					title: 'Clients',
					root: true,
					icon: 'group',
					page: '/client-schedular',
					permission: [environment.customKeys.roleCaseManager],
					bullet: 'dot'
				},
				{
					title: 'Case Managers & Clients',
					root: true,
					icon: 'group',
					page: '/admin-schedular',
					permission: [environment.customKeys.roleAdmin, environment.customKeys.roleCaseManagerSuper],
					bullet: 'dot'
				},
				{
					title: 'Notifications',
					root: true,
					icon: 'notifications',
					page: '/notifications',
					bullet: 'dot'
				},
				{
					title: 'Client Management',
					root: true,
					icon: 'settings',
					page: '/users-configuration',
					permission: [environment.customKeys.roleCaseManager],
					bullet: 'dot'
				},
				{
					title: 'Users Management',
					root: true,
					icon: 'settings',
					page: '/users-configuration',
					permission: [environment.customKeys.roleAdmin, environment.customKeys.roleCaseManagerSuper],
					bullet: 'dot'
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
