// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverMainUrl: 'https://staging.calendar-toolbox.interactivelife.me/',
  serverUrl: 'https://staging.api.calendar-toolbox.interactivelife.me/api/',
  scheduleUrl: 'https://staging.api.calendar-toolbox.interactivelife.me/api/',
  serverName: 'Calendar Toolbox',
  aliveAppIntervalMinutes: 60, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 30, // if you keep app inactive pop a message
  subServerName: '',
  productId: '78ccfaa3-7fba-4926-b4d4-34d44b039754',
  projectName: 'calendar-toolbox',
  profileImageURL: '',
  orgType: 'calendar-toolbox',
  serverHostUrl: 'https://staging.calendar-toolbox.interactivelife.me/',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',
    roleCaseManager: '64dc639a4096f85368c00770',
    roleCaseManagerSuper: '6638b2257bedf1818cd3e735',
    roleClient: '64dc69d44096f85368c00776',
  },
  customKeysView: {
    '5eb137f1d3123a407c7c97cc': 'Admin',
    '64dc639a4096f85368c00770': 'Case Manager',
    '6638b2257bedf1818cd3e735': 'Case Management Supervisors',
    '64dc69d44096f85368c00776': 'Client',
  },
  rollbar: {
    apiKey: '13a20e83201842a68d4f46ddf2c81115',
  },
  environment: 'staging',
  firebaseVapidKey: 'BKKe-G7OE6EE4468bt8XjuDDZJkhVBspAca6lr6BeUTv5oiugOAU26hW437ZDUlDVJLmjFqRmvTL3IfNFX3NYzc',
  firebaseConfig: {
    apiKey: "AIzaSyDCPR2JkAGkGcI5TwRUfiSU-nuQtEHp-jU",
    authDomain: "calendar-toolbox-2105a.firebaseapp.com",
    projectId: "calendar-toolbox-2105a",
    storageBucket: "calendar-toolbox-2105a.appspot.com",
    messagingSenderId: "635824651672",
    appId: "1:635824651672:web:148375a0db9f87ca35ffed",
    measurementId: "G-RPM4BTZ6HS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
