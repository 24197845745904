<mat-toolbar class="nav" color="primary">
    <!-- <button type="button" mat-icon-button class="visible-sm" (click)="toggleSidebar()" exceptclick>
        <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
    </button> -->
    <!-- <div class="nav-brand" title="{{subTitle}}">
        <img id="topNavLogo" src="assets/images/mht-topnav-logo.png" class="circle z-depth-2 responsive-img activator"
            alt="{{title}}" (click)="goHome()">

    </div> -->
    <div class="logo">
        <span *ngIf="screenWidth >= 540">
            {{title}}
        </span>
        <span *ngIf="screenWidth < 540">
            {{subTitle}}
        </span>
        <span class="subtitle" *ngIf="subTitle">{{subTitle }}</span>
    </div>

    <span class="nav-spacer"></span>
    <div class="profile">

        <span class="date">{{date | date : "EEEE, MMMM d, y" }}</span>
        <div class="notification-btn">
            <mat-icon (click)="gotoNotifications()" class="notifications">notifications<div
                    *ngIf="showNotificationUnRead" class="unread"></div>
            </mat-icon>
        </div>
        <div style="position: relative;" *ngIf="currentNotification">
            <div class="notification-drawer">
                <mat-icon class="close" (click)="closeNotification()">close</mat-icon>
                <div class="body">
                    {{currentNotification}}
                </div>
            </div>
        </div>
        <!-- <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" (click)="handleMenuOpened()"
            class="notifications-menu-button" [class.has-new]="hasNew">
            <mat-icon>notifications</mat-icon>
        </button> -->
        <!-- <mat-menu #notificationsMenu="matMenu">
        <div class="notifications-top-nav-box">

            <div *ngIf="!notificationsLoaded" style="text-align: center;">
                <mat-spinner diameter="30" style="margin: auto;"></mat-spinner>
            </div>

            <div *ngIf="!notificationsList.length && notificationsLoaded">
                <p style="text-align: center;">No notifications yet</p>
            </div>

            <span *ngIf="notificationsList.length && notificationsLoaded">
                <div *ngFor="let item of notificationsList">
                    <button mat-menu-item [class.notification-badge]="item.viewed == false"
                        (click)="viewNotification(item)" style="font-size: 14px;">{{item.title}}</button>
                </div>
            </span>
        </div>
        <div>
            <button mat-menu-item
                style="width:35%; float: left; padding: 0px; margin-right: 0px; text-align: center;line-height: 35px; height: 35px;"
                (click)="moveToNotificationsPage()">View all</button>
            <button mat-menu-item color="primary"
                style="width:Calc(65% - 5px); padding: 0px; float: left; text-align: center; background-color: #32A5D7; color: #ffffff;line-height: 35px; height: 35px;"
                (click)="markAllAsRead()">Mark all as read</button>
        </div>
    </mat-menu> -->
        <button mat-icon-button [routerLink]="[]" title="My Profile" [matMenuTriggerFor]="profileMenu"
            style="margin-left:10px;">
            <mat-icon style="font-size: 30px;line-height: 30px;height: 30px;width: 30px;"
                *ngIf="!pictureLink || pictureLink === ''">account_circle</mat-icon>
            <img *ngIf="pictureLink && pictureLink !== ''" [src]="pictureLink"
                class="circle-img-btn z-depth-2 responsive-img activator">
        </button>
        <mat-menu #profileMenu="matMenu">
            <div class="profile-top-nav-box">
                <button mat-menu-item (click)="viewProfile()" style="font-size: 14px;">Profile
                </button>

                <button mat-menu-item (click)="onLoggedout()" style="font-size: 14px;">Logout
                </button>
            </div>
        </mat-menu>
        <!-- <div class="profile-menu" [matMenuTriggerFor]="profileMenu">

            <div class="dr-name">{{selectedUser.firstName}}</div>

            <button mat-icon-button>
                <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #profileMenu="matMenu">
                <div class="profile-top-nav-box">
                    <button mat-menu-item (click)="viewProfile()" style="font-size: 14px;">Profile
                    </button>

                    <button mat-menu-item (click)="onLoggedout()" style="font-size: 14px;">Logout
                    </button>
                </div>
            </mat-menu>
        </div> -->
    </div>




</mat-toolbar>
<audio #notification src="assets/files/notification.mp3" preload="auto" autostart="0"></audio>