<div class="col-xl-12 fileGalleryPopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content" style="max-height: 50vh;">
		<div>
			<mat-form-field class="example-chip-list" appearance="fill">
				<mat-label>{{'Recipients' | translate}}</mat-label>
				<mat-chip-list #chipList aria-label="Email selection" required="true">
					<mat-chip *ngFor="let email of emails" (removed)="remove(email)">
						{{email}}
						<button matChipRemove>
							<mat-icon>cancel</mat-icon>
						</button>
					</mat-chip>
					<input #targetInput placeholder="New email..." [matChipInputFor]="chipList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
						(matChipInputTokenEnd)="add($event)">
				</mat-chip-list>
				<div *ngIf="errorMessage" class="emailError">
					{{errorMessage | translate}}
				</div>
				<div *ngIf="!errorMessage" class="emailNote">
					{{'email1@example.com, email2@example.com, email3@example.com, ...' | translate}}
				</div>
			</mat-form-field>
			<mat-form-field class="example-full-width" appearance="fill">
				<mat-label>Notes</mat-label>
				<textarea matInput [(ngModel)]="notes" placeholder="Note..." hintLabel="Max 1000 characters"
					maxlength="1000"></textarea>
				<mat-hint align="end">
					{{notes.length || 0}}/1000
				</mat-hint>
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Cancel' | translate}}</button>
			<button mat-raised-button (click)="sendEmail()">{{'Send' | translate}}</button>
		</div>
	</div>
	<br />
</div>