import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
} from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/version';
import { RequestService } from './request.service';
import { BehaviorSubject } from 'rxjs';

let rollbarConfig = {
  accessToken: environment.rollbar.apiKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.environment === 'local' ? false : true,
  payload: {
    environment: environment.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: VERSION.git,
        guess_uncaught_frames: true,
      },
    },
  },
};

@Injectable()
export class LoggerService {
  public errorObject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  log(error) {
    // console.log('My Error:', error);
    this.errorObject.next(error);
  }
}
export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public showError: boolean = true;

  constructor(private injector: Injector, private logger: LoggerService,
    private requestService: RequestService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
  }

  handleError(error: any) {
    // Obtain dependencies at the time of the error
    // This is because the GlobalErrorHandler is registered first
    // which prevents constructor dependency injection
    let userDetails = this.requestService.currentUser;
    if (userDetails)
      this.rollbar.configure({
        payload: {
          person: {
            id: userDetails._id,
            email: userDetails.email,
            name: userDetails.name
          }
        }
      });

    this.rollbar.error(error.originalError || error);

    // const err = {
    //   message: error.message ? error.message : error.toString(),
    //   stack: error.stack ? error.stack : '',
    // };
    if (error instanceof HttpErrorResponse) {
      //Backend returns unsuccessful response codes such as 404, 500 etc.
      console.error('Backend returned status code: ', error.status);
      console.error('Response body:', error.message);
      // router.navigate(['/error/400']);
    } else {
      //A client-side or network error occurred.
      this.logger.log(error.message);
    }
    if (this.showError) {
      this.showError = false;
      this.handleError(error);
    }
    // Re-throw the error
    throw error;
  }
}
