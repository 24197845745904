import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { initializeApp } from 'firebase/app';
import { MessagePayload, deleteToken, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { RequestService } from './request.service';
import { LayoutUtilsService } from './utils/layout-utils.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseHandlersService {

  currentMessage = new BehaviorSubject(null);
  token: string = '';
  // topic = 'notification';
  firebaseApp: any = undefined;

  constructor(private requestService: RequestService, private notificationService: NotificationService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService,) {
    navigator.serviceWorker.addEventListener('message', event => {
      // console.log('event', event)
      if (!event.data?.shelter) {
        this.currentMessage.next(event.data);
        this.notificationService.showNotificationUnRead.next(true);
      }
      else
        this.notificationService.showShelterNotification.next(event.data.data);
    });

    // if (Notification.permission === 'granted') {
    //   // try to subscribe
    //   // console.error('The user has blocked notifications.');
    // } else if (Notification.permission === 'denied') {
    //   this.layoutUtilsService.showNotification(this.translate.instant('Notifications were blocked by the browser. Enable to receive notifications.'), 'Dismiss');
    //   // console.error('The user has blocked notifications.');
    // } else {
    //   // The user has not yet responded to the permission request
    // }
  }

  public async initialize() {
    if (await isSupported()) {
      try {
        this.firebaseApp = initializeApp(environment.firebaseConfig);
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
          // console.log('Message received. ', payload);
          this.currentMessage.next(payload);
          // Update the UI to include the received message.

        });
      }
      catch (e) { }
      // this.requestPermission();
      // this.listen(this.firebaseApp);
    }
  }

  public registerDevice(currentUserId: string, callback?: any) {
    if (Notification.permission === 'granted') {
      if (currentUserId) {
        const messaging = getMessaging();
        getToken(messaging,
          { vapidKey: environment.firebaseVapidKey }).then(
            (currentToken) => {
              this.token = currentToken;
              this.requestService.postRequest({
                userId: currentUserId,
                type: "Web",
                deviceId: this.token
              }, 'notify/register', (data, error) => {
                if (callback)
                  callback();
              });
            }).catch(e => console.log(e))
      }
    }
  }

  sendNotificationToDevice(responderId: string, payload: any) {
    this.requestService.postRequest('notify/firebase/' + responderId + '/send', payload, (data, error) => {

    });
  }

  sendNotificationToType(type: string, payload: any) {
    this.requestService.postRequest('notify/firebase/' + type, payload, (data, error) => {

    });
  }

  unsubscribeTopic(topic: string, callback: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.token && topic)
        this.requestService.postRequest({
          deviceToken: this.token,
          topic: topic
        }, 'notify/unsubscribe/topic', (data, error) => {
          if (data)
            resolve(data);
          else if (error)
            reject(error);

          callback();
        });
    });
  }

  unregisterDevice(userId: string, deviceId: string, callback: any, notValidToken: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      const messaging = getMessaging();
      getToken(messaging).then((currentToken) => {
        deleteToken(messaging).then(() => {
          if (currentToken && !notValidToken)
            this.requestService.postRequest({
              userId: userId,
              deviceId: deviceId,
              type: "Web"
            }, 'notify/device/delete', (data, error) => {
              if (data)
                resolve(data);
              else if (error)
                reject(error);

              callback();
            });
          else {
            resolve(undefined);
            callback();
          }
        }).catch((err) => {
          console.log(err);
          callback();
        });
      });
    });
  }

  async logout(notValidToken: boolean = false) {
    // if (this.requestService.currentUser?.shelter) {
    if (this.token)
      this.unregisterDevice(this.requestService._currentUser._id, this.token, () => {
        this.requestService.logOutApi();
      }, notValidToken);
    else
      this.requestService.logOutApi();
    // }
    // else {
    //   this.unsubscribeTopic(this.topic, () => {
    //     this.requestService.logOutApi();
    //   })
    // }
  }
}