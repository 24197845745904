import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutUtilsService, RequestService, StoreService } from '../../../shared/services';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { NotificationService } from '../../services/notification.service';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public pushRightClass: string;
  public selectedOrganization: string = '';
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public selectedDatafilters: any = undefined;
  public hasNew: boolean = false;
  public pageSize = 10;
  date: Date;
  screenWidth: number;
  public pictureLink: string = 'assets/images/profile.png';

  currentRoute: string = '';

  currentNotification: any = undefined;
  notificationTimer: any = undefined;
  showNotificationUnRead: boolean = false;
  showConfSubSubMenu: boolean = false;
  notificationAudio: any = undefined;
  @ViewChild('notification') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    if (ref)
      this.notificationAudio = ref.nativeElement;
  }
  @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
  constructor(private requestService: RequestService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private notificationService: NotificationService, private firebaseHandler: FirebaseHandlersService) {
    this.userType = this.requestService.getUserType();
    router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.currentRoute = data.url;
      }
    });
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.date = new Date();
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.pictureLink = data.pictureLink;
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.selectedOrganization = data._id;
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentPageTitle.subscribe((data) => {
        if (data) {
          this.subTitle = data;
        }
      })
    );


    this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
      if (this.currentRoute !== '/notifications' || !show) {
        this.showNotificationUnRead = show;
      } else {
        this.showNotificationUnRead = false;
      }
    }));

    this.subscriptions.push(this.firebaseHandler.currentMessage.subscribe((message: any) => {
      // console.log('message', message)
      if (message && message.notification) {
        if (message.messageType !== 'notification-clicked') {
          if (this.notificationAudio) {
            this.notificationAudio.load();
            this.notificationAudio.loop = 0;
            this.notificationAudio.volume = 0.25;
            this.notificationAudio.play().catch((error: any) => { });
          }
          this.notificationService.showNotificationUnRead.next(true);
          this.notificationService.refreshData.next(true);
          this.currentNotification = message.notification.body;
          if (this.notificationTimer) {
            clearTimeout(this.notificationTimer);
            this.notificationTimer = undefined;
          }
          this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
        } else {
          this.notificationService.showNotificationUnRead.next(true);
          this.currentNotification = null;
        }
      }
      else
        this.currentNotification = null;
    }));

    this.getNotifications();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }


  // setSelectedOrganization(e, reload = true) {
  //   this.requestService.orgId = "5e5e69dd2023950162466258";
  //   this.storeService.set('orgId', '5e5e69dd2023950162466258');
  // }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    // this.requestService.logout();
    this.firebaseHandler.logout();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }

  formatDate(seconds) {


    let output = new Date(seconds * 1000);

    return moment(output).format("YYYY-MM-DD HH:mm");


  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '850px',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: this.selectedUser,
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }

  /**
   * @description - remove the red dot on the notifications if the menu is opened
   */
  public handleMenuOpened() {
    if (this.hasNew) this.hasNew = !this.hasNew;
  }
  public goHome() {
    this.router.navigate(['/dashboard']);
  }
  private getNotifications() {
    this.notificationService.getNotifications('', 0, 1).then((data: any) => {
      if (localStorage.getItem('last-seen-notifications') && data.results.length > 0) {
        let lastSeen = moment(Number(localStorage.getItem('last-seen-notifications'))).utc();
        if (lastSeen && lastSeen.isBefore(moment(data.results[0]?.createdAt))) {
          this.showNotificationUnRead = true;
        }
      }
    }).catch((error: any) => {

    }).finally(() => {

    });
  }
  closeNotification() {
    this.currentNotification = null;
  }

  gotoNotifications() {
    this.router.navigate(['/notifications']);
  }
}
