import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../../../shared/components/iframe-dialog/iframe-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  sliderArray: any[] = [];
  loading: boolean = false;
  dataType: string = 'banner';
  transitionTime: number = 7;
  selectedIndex = 0;
  bannerHeight: string = undefined;
  bannerWidth: string = '100%';
  public _bannerId: string = undefined;
  @Input()
  set bannerId(bannerId: any) {
    this._bannerId = bannerId;
    if (bannerId) {
      this.loadData(bannerId);
    } else {
      this.sliderArray = [];
    }
  }
  get bannerId(): any {
    return this._bannerId;
  }

  public currentRoomUserRole = undefined;

  public currentSessionUserRole = undefined;
  masterModeratorId: string = undefined;
  leads: any = [];

  // public currentTzOffset = moment().utcOffset();
  constructor(
    protected layoutUtilsService: LayoutUtilsService,
    private requestService: RequestService, protected translate: TranslateService, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef) {
    //remove below when finished
    // this.sliderArray = [
    //  {"img": "/assets/temp/1.jpg", "alt": "", link: 'https://www.google.com'},
    //  {"img": "/assets/temp/2.jpg", "alt": "", link: 'https://www.google.com'},
    //  {"img": "/assets/temp/3.jpg", "alt": "", link: 'https://www.google.com'},
    // ];
    this.sliderArray = [];
    // this.bannerHeight = '15vh';
  }

  ngOnInit() {
    // this.loadData(this.bannerId);
    this.subscriptions.push(
      interval(this.transitionTime * 1000)
        .subscribe((val) => {
          let selectedIndex = this.selectedIndex;
          selectedIndex++;
          if (selectedIndex < this.sliderArray.length) {
            this.selectedIndex = selectedIndex;
          } else {
            this.selectedIndex = 0;
          }
          // console.log('called', this.selectedIndex);
        })
    );
  }
  protected loadData(id) {
    if (!this.loading) {
      this.loading = true;
      this.requestService.getSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          if (data.results && data.results.hasOwnProperty('pictures')) {
            this.sliderArray = data.results.pictures;
            this.transitionTime = data.results.transitionTime;
            if (!this.bannerHeight || this.bannerHeight === '') {
              this.bannerHeight = data.results.height;
            }
            // if(!this.bannerWidth || this.bannerWidth === ''){
            //   this.bannerWidth = data.results.width;
            // }
          }
          if (!this.bannerHeight || this.bannerHeight === '') {
            this.bannerHeight = '100%';
          }
          // if(!this.bannerWidth || this.bannerWidth === ''){
          //   this.bannerWidth = '100%';
          // }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
        }
        this.loading = false;
      });
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  selectBanner(e, type, link) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (link && link !== '') {
      if (type === 'room') {
        this.viewData(link)
      } else if (type === 'tile') {
        // do nothing
      } else if (type === 'page') {
        this.router.navigate(['/' + link], { relativeTo: this.activatedRoute });
      } else {
        window.open(link, '_blank');
      }
    }
  }
  public viewData(roomId) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData('room', roomId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let roomData = data.results;
          this.currentRoomUserRole = this.requestService.getUserRoomRole(roomData);
          // console.log('this.currentRoomUserRole', this.currentRoomUserRole);
          // console.log('this.roomData.opentoall', this.roomData.opentoall);
          if (this.currentRoomUserRole === 'owner' || this.currentRoomUserRole === 'admin' || (roomData.opentoall && this.currentRoomUserRole === 'edit')) {
            this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          } else {
            if (roomData.opentoall) {
              this.getSessions(roomData);
            } else {
              this.layoutUtilsService.showNotification(this.translate.instant('You have no access to this ') + this.translate.instant('room'), this.translate.instant('Dismiss'));
            }
          }
        }
      });
    }
  }
  getSessions(roomData) {
    let roomId = roomData._id;
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { '$or': [{ "opentoall": true }, { "users._id": { "$in": [this.selectedUser._id] } }, { "createdBy": { "$eq": this.selectedUser._id } }] }]
    // };
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { "opentoall": true }]
    // };
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt', 'url'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        let sessionsList = data.results;
        if (sessionsList && sessionsList.length > 0) {
          let currentSession = undefined;
          let currentDate: Moment = moment.utc();
          if (sessionsList.length > 1) {
            let activeSession = undefined;
            for (let lec of sessionsList) {
              if (lec.active) {
                activeSession = lec;
                break;
              }
            }
            currentSession = activeSession;
            if (!currentSession) {
              for (let lec of sessionsList) {
                let startD: Moment = moment.utc(lec.startsAt).local();
                let endD: Moment = moment.utc(lec.endAt).local();
                if ((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) {
                  activeSession = lec;
                  break;
                }
              }
            }
            currentSession = activeSession;
          } else {
            currentSession = sessionsList[0];
          }
          if (currentSession) {
            this.currentSessionUserRole = this.requestService.getUserSessionRole(currentSession);
            this.leads = this.requestService.getSessionRoleByType(currentSession, 'lead');
            this.masterModeratorId = this.requestService.getSessionHost(currentSession);
            if (this.leads.length == 0) {
              this.layoutUtilsService.showNotification('A ' + this.translate.instant('lead') + ' needs to be assigned', this.translate.instant('Dismiss'));
            } else {
              if (!this.masterModeratorId) {
                this.layoutUtilsService.showNotification(this.translate.instant('A host needs to be assigned'), this.translate.instant('Dismiss'));
              } else {
                if (currentSession.opentoall || this.currentSessionUserRole !== 'anonymous') {
                  if (currentSession.url && currentSession.url.trim() !== '') {
                    window.open(currentSession.url.trim(), '_blank');
                  } else {
                    // this.router.navigate(['/rooms', roomId, 'sessions', currentSession._id], { relativeTo: this.activatedRoute });
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                      this.router.navigate(['/rooms', roomId, 'sessions', currentSession._id], { relativeTo: this.activatedRoute }));
                    // window.location.href = '/#/rooms/' + roomId + '/sessions/' + currentSession._id;
                    // setTimeout(()=>{
                    //   window.location.reload();
                    // }, 100);
                  }
                  return;
                } else {
                  this.layoutUtilsService.showNotification('Oops! This ' + this.translate.instant('session') + ' is limited to specific attendees.', 'Dismiss');
                }
              }
            }
          } else {
            this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          }
        } else {
          // this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
          this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
        }
      }
    });
  }
}
