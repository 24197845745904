import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent, BehaviorSubject, merge, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../services';
import { urlSafeBase64Encoding } from '../../helpers';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { environment } from '../../../../environments/environment';
import { MultipleModalDialogComponent } from '../custom-multiple-upload-dialog/custom-multiple-upload-dialog.component';

interface ViewDialogData {
	title: string;
	data: any;
	targetId: string;
	noSelection: boolean;
	dataType: string;
	dataTypeDisplay: string;
	multipleSelection: boolean;
	maxHeight: number;
	maxWidth: number;
	maxFiles: number;
}

@Component({
	selector: 'app-files-event-dialog-modal',
	templateUrl: './custom-files-event-dialog.component.html',
	styleUrls: ['./custom-files-event-dialog.component.scss']
})
export class ModalFilesEventDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public passingThru: boolean = false;
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public noSelection: boolean = false;
	public multipleSelection: boolean = false;
	public multipleSelected: any = {};
	public multipleSelectedObject: any = {};
	public newImage: boolean = false;
	public production: boolean = environment.production;
	public dataType: string = 'media';
	public dataTypeDisplay: string = this.translate.instant('Media');
	public dataList: any[] = [];
	public dataListFolder: any[] = [];
	public fileSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public selectedRatio: number = 1;
	public folderText: string = '';
	public folderSelected: string = undefined;
	public targetId: string;
	public searchVal: string = '';
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public allowedExtensions: string[] = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'];
	public allowedAcceptExtensions: string = '.pdf,.doc,.docx,image/png,image/jpg,image/jpeg';

	public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	maxFiles = 5;

	// @ViewChild('searchInput') searchInput: ElementRef;

	@ViewChild('imageInput') private imageInput: ElementRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<ModalFilesEventDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalAudioGalleryDialogComponent', data);
		this.noSelection = data.noSelection || false;
		this.multipleSelection = data.multipleSelection || false;
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					if (this.data.hasOwnProperty('dataType'))
						this.dataType = this.data.dataType;
					if (this.data.hasOwnProperty('dataTypeDisplay'))
						this.dataTypeDisplay = this.data.dataTypeDisplay;
					if (this.data.hasOwnProperty('targetId'))
						this.targetId = this.data.targetId;
					if (this.data.hasOwnProperty('maxFiles'))
						this.maxFiles = this.data.maxFiles;
					this.loadData();
				}
			})
		);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getSingleData(this.dataType, this.targetId, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					// console.log('dataList', data);
					let dataList = [];
					if (data.results.hasOwnProperty('files')) {
						dataList = data.results['files'];
					}
					dataList = dataList.map((itm) => {
						itm['type'] = 'file';
						let fileExt = itm['url'].split('.').pop();
						let extension = fileExt.toLowerCase();
						const fileNames = itm['url'].split('/').pop();
						if (['png', 'jpg', 'jpeg'].includes(extension)) {
							itm['type'] = 'image';
						} else if (extension === 'pdf') {
							itm['type'] = 'pdf';
						} else if (extension === 'doc' || extension === 'docx') {
							itm['type'] = 'document';
						}
						itm['name'] = fileNames;
						return itm;
					})
					this.dataList = dataList;
				}
				this.loading = false;
			});
		}
	}
	public open(e, event: any) {
		window.open(event.url, '_blank');
	}
	public delete(e, id: any) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		if (!this.loading) {
			const _title: string = this.translate.instant('Deleting') + ' ' + this.dataTypeDisplay;
			const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
			const _waitDesciption: string = this.translate.instant('Deleting') + '...';

			const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				}
				this.realDelete(id);
			});
		}
	}
	public realDelete(id: any) {
		const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.deleteSingleDataByApi(this.dataType + '/file', id, 'delete', (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
					this.fileSelected = undefined;
					this.imageUrlPath = undefined;
					// this.pageNumber = 1;
					this.loadData();
				}
			});
		}
	}
	public setFileSelected(val) {
		this.fileSelected = val;
	}
	// termConfiguration(): any {
	// 	const searchText: string = this.searchInput.nativeElement.value;
	// 	return searchText;
	// }
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}

	onBrowseFiles(target: any): void {
		// this.readFiles(target.files);
		// if (target.files.length > 1) {
		const dialogRef = this.dialog.open(MultipleModalDialogComponent, {
			width: '100vw',
			disableClose: false,
			autoFocus: false,
			data: {
				title: this.translate.instant('Multiple') + ' ' + this.translate.instant('File') + ' ' + this.translate.instant('Upload'),
				path: this.dataType + '/file/upload/' + this.targetId,
				limitNumberOfImages: this.maxFiles,
				alreadyUploadedImagesLength: this.dataList.length,
				target: target,
				isEncoded: 'false',
				folder: this.folderSelected,
				allowedExtensions: this.allowedExtensions,
				allowedAcceptExtensions: this.allowedAcceptExtensions,
				addName: false
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (this.imageInput) {
				this.imageInput.nativeElement.value = "";
			}
			if (result) {
				this.loadData();
			}
		});
		// } else {
		// 	this.readFiles(target.files);
		// }
	}


	setMultipleSelected(event, id, itm) {
		if (event.checked) {
			this.multipleSelected[id] = true;
			this.multipleSelectedObject[id] = itm;
		} else {
			if (this.multipleSelected.hasOwnProperty(id)) {
				delete this.multipleSelected[id];
			}
			if (this.multipleSelectedObject.hasOwnProperty(id)) {
				delete this.multipleSelectedObject[id];
			}
		}
		// console.log('this.multipleSelected', this.multipleSelected);
		// console.log('this.multipleSelectedObject', this.multipleSelectedObject);
	}
	multiSelect() {
		let imagesList = [];
		let multipleSelectedObjectKey = Object.keys(this.multipleSelectedObject);
		for (let itmId of multipleSelectedObjectKey) {
			imagesList.push(this.multipleSelectedObject[itmId]);
		}
		this.closeModal(imagesList);

	}
	hasSelected() {
		let multipleSelectedObjectKey = Object.keys(this.multipleSelectedObject);
		return multipleSelectedObjectKey.length > 0 ? true : false;

	}
}
