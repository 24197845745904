<div class="col-xl-12 fileGalleryPopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content" style="max-height: 50vh;">
		<div class="itemViewParent">
			<mat-card class="itemView" *ngFor="let itm of dataList; let index=index" (click)="open($event, itm)">
				<mat-card-header>
					<mat-card-title>{{itm.name}}</mat-card-title>
					<em class="material-icons deleteit" (click)="delete($event, itm._id)">delete_forever</em>
				</mat-card-header>
				<img *ngIf="itm.type === 'file'" mat-card-image src="assets/images/files.png">
				<img *ngIf="itm.type === 'image'" mat-card-image src="{{itm.url}}">
				<img *ngIf="itm.type === 'document'" mat-card-image src="assets/images/files.png">
				<img *ngIf="itm.type === 'pdf'" mat-card-image src="assets/images/files.png">
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="{{allowedAcceptExtensions}}"
				multiple="true" id="image_selector" (change)="onBrowseFiles($event.target)">
			<button [disabled]="this.dataList.length > 5" mat-raised-button (click)="imageInput.click()">{{'Upload new
				files' | translate}}</button>
		</div>
	</div>
	<br />
</div>