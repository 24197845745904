import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LayoutUtilsService, LoaderService, RequestService } from '../../services';
import { TranslateService } from '@ngx-translate/core';



interface DialogData {
  targetDataType: string;
  targetDataTypeDisplay: string;
  dataType: string;
  title: string;
  data: any;
  filters: any;
  cmId: string;
  type: string;
  limit: number;
  limitMessage: string;
  columnData: string;
  confirmData: any;
}


@Component({
  selector: 'clients-assign-dialog',
  templateUrl: './clients-assign-dialog.component.html',
  styleUrls: ['./clients-assign-dialog.component.scss']
})
export class ClientsAssignDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: any = undefined;
  public selectedUser: any;
  public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public pagination: boolean = true;
  public canSearch: boolean = true;
  public optionData: any = [];
  public selectedData: any = [];
  public searchVal: string = '';
  public tableDataTotal: number = 0;

  /* pagination Info */
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private requestService: RequestService, private loaderService: LoaderService, private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    public dialogRef: MatDialogRef<ClientsAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
  }

  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator.pageIndex = 0;
            this.pageNumber = 1;
            this.loadDataSearch();
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.getClientsList();
        }
      })
    );
  }
  getClientsList() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getClientsList(this.data.cmId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        if (data) {
          // console.log('getClientsList', data.results);
          this.selectedData = data.results.map((itm) => {
            itm['text'] = itm['name'];
            return itm;
          });;
        } else {
          this.selectedData = [];
        }
        this.loading = false;
        this.loadDataSearch();
      });
    }
  }
  public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public loadDataSearch() {
    if (this.loading && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }
      let excludeList = [];
      if (this.selectedData.length > 0) {
        excludeList = this.selectedData.map(itm => itm._id)
      }
      // for (let dt of this.selectedData) {
      //   excludeList.push(dt._id);
      // }
      this.apiCallSubscription = this.requestService.getDataListSummary(this.data.targetDataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any = data;
          //console.log('dataReturned', dataReturned);
          if (dataReturned.pagination) {
            this.tableDataTotal = dataReturned.pagination.total;
            this.paginatorTotal.next(dataReturned.pagination.total);
          }
          this.optionData = dataReturned.results.map((itm) => {
            itm['name'] = itm['text'];
            return itm;
          });
        } else {
          this.optionData = [];
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  selectData(): void {
    if (this.data.limit) {
      if (this.selectedData.length > this.data.limit) {
        if (this.data.limitMessage) {
          this.layoutUtilsService.showNotification(this.data.limitMessage, 'Dismiss');
        } else {
          this.layoutUtilsService.showNotification('You cannot select more than ' + this.data.limit + ' ' + this.data.targetDataTypeDisplay, 'Dismiss');
        }
      } else {
        this.continueAssign();
      }
    } else {
      this.continueAssign();
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  /** FILTRATION */
  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  continueAssign() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.requestService.assignClients(this.data.cmId, this.selectedData, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        this.loaderService.display(false);
        if (data) {
          this.layoutUtilsService.showNotification(this.data.targetDataTypeDisplay + ' ' + this.translate.instant('assigned successfully'), this.translate.instant('Dismiss'));
          this.dialogRef.close(this.selectedData);
        }
      });
    }
  }
}
