<form #multipleUpdateForm="ngForm" class="multipleUpdateForm" *ngIf="data">
    <div class="col-xl-12">
        <h1 mat-dialog-title>{{data.title}}</h1>
        <div mat-dialog-content>
            <div>
                <mat-card>
                    <mat-card-content>
                        <h2 class="example-h2">{{'Files'}}</h2>

                        <section class="example-section" *ngIf="limitNumberOfImages">
                            You can upload maximum {{limitNumberOfImages}} file slots, you have {{limitNumberOfImages -
                            (currentFiles.length +
                            alreadyUploadedImagesLength)}} empty slot.
                        </section>
                        <section *ngIf="errorMessage && errorMessage !== ''" class="example-section">
                            {{errorMessage}}
                        </section>
                        <ng-container *ngIf="currentFiles.length > 0">
                            <table>
                                <tr>
                                    <th style="width: 30%;">File Name</th>
                                    <th style="width: 40%;">Status</th>
                                    <th style="width: 10%;">Size</th>
                                    <th style="width: 20%;">Action</th>
                                </tr>
                                <tr *ngFor="let progressInfo of currentFiles;let index = index">
                                    <td>{{ progressInfo.name }}</td>
                                    <td>
                                        <mat-progress-bar *ngIf="!progressInfo.error && !progressInfo.message"
                                            class="example-margin" [color]="color" [mode]="mode"
                                            [value]="progressInfo.value">
                                        </mat-progress-bar>
                                        <span *ngIf="progressInfo.message">{{progressInfo.message}}</span>
                                    </td>
                                    <td>{{ progressInfo.size }}</td>
                                    <td>
                                        <button mat-raised-button (click)="deleteFile(index)"
                                            style="margin-right: 10px;"
                                            *ngIf="progressInfo.action && progressInfo.action != 'upload'">{{'Remove' |
                                            translate}}</button>
                                        <button mat-raised-button (click)="cancelFile(index)"
                                            *ngIf="!progressInfo.error && progressInfo.value < 100">{{'Cancel' |
                                            translate}}</button>
                                    </td>
                                </tr>
                            </table>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div mat-dialog-actions>
            <button color="danger" [disabled]="loading" mat-raised-button (click)="closeModal()">{{'Close' |
                translate}}</button>
            <button mat-raised-button (click)="imageInput.click()"
                *ngIf="loading || (currentFiles.length >= limitNumberOfImages && limitNumberOfImages !== 0)"
                disabled="true"
                [title]="'You can upload up to '+limitNumberOfImages+' files. Please remove some of the previously uploaded files then try again.'">{{isImageUpload
                ? 'Select new
                images' : 'Select new
                files'}}</button>
            <button mat-raised-button (click)="imageInput.click()"
                *ngIf="!(loading || (currentFiles.length >= limitNumberOfImages && limitNumberOfImages !== 0))">{{isImageUpload
                ? 'Select new
                images' : 'Select new
                files'}}</button>
            <button mat-raised-button (click)="uploadFiles()" disabled="true"
                *ngIf="loading || (hasFormErrors && currentFiles.length > 0) || ((currentFiles.length + alreadyUploadedImagesLength) > limitNumberOfImages && limitNumberOfImages !== 0)"
                [title]="'You can upload up to '+limitNumberOfImages+' files. Please remove some of the previously uploaded files then try again.'">{{'Upload
                Files' | translate}}</button>
            <button mat-raised-button (click)="uploadFiles()"
                *ngIf="!(loading || (hasFormErrors && currentFiles.length > 0) || ((currentFiles.length + alreadyUploadedImagesLength) > limitNumberOfImages && limitNumberOfImages !== 0))">{{'Upload
                Files' | translate}}</button>
            <input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="{{allowedAcceptExtensions}}"
                multiple="true" id="image_selector" (change)="selectFiles($event.target)">
        </div>
        <br />
    </div>
</form>