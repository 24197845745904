import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsvExporterService, LayoutUtilsService, LoaderService, RequestService } from '../../services';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { sanitizeFilename } from '../../helpers';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { validate } from 'uuid';

interface ViewDialogData {
	title: string;
	momentDateFormat: string;
	selectedClient: any;
	selectedCM: any;
	metaFieldSetting: any;
	usersAll: any[];
	users: any[];
	cmusers: any[];
	clients: boolean;
}

@Component({
	selector: 'app-scheduler-export-dialog-modal',
	templateUrl: './custom-scheduler-export-dialog.component.html',
	styleUrls: ['./custom-scheduler-export-dialog.component.scss']
})
export class ModalSchedulerExportDialogComponent {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public metaFieldSetting: any;
	public safeEnumListObj: any;
	public wrnaEnumListObj: any;
	public isAdmin: boolean = false;
	public isSuperAdmin: boolean = false;
	public isCaseManagerSuper: boolean = false;
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public clients: boolean = false;
	public viewMode: string = 'month';
	public dataVal: string = '';
	public selectedClients: string[] = [];
	public selectedClientsObject: any = {};
	public selectedClient: string = '';
	public selectedCMs: string[] = [];
	public selectedCMsObject: any = {};
	public selectedCM: string = '';
	public selectedExportType: string = 'calendar-items';
	public selectedCategory: string = 'all';
	public selectedSubCategory: string = 'all';
	public selectedFacility: string = 'all';
	public selectedSAFE: string[] = [];
	public selectedWrna: string[] = [];
	public allSelectedWrna: boolean = true;
	public usersAll: any[] = [];
	public users: any[] = [];
	public cmusers: any[] = [];
	public dataType: string = 'event';
	public maxRange: number = 1;
	public actDateFormat: string = 'DD/MM/YYYY';
	public momentDateFormat: string = 'DD-MM-YYYY';
	public selectedStartDate = undefined;
	public selectedEndDate = undefined;
	public menuIsOpen = false;
	// public currentTzOffset = moment().utcOffset();

	constructor(private zone: NgZone,
		protected layoutUtilsService: LayoutUtilsService, protected translate: TranslateService,
		private requestService: RequestService,
		public dialogRef: MatDialogRef<ModalSchedulerExportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private csvService: CsvExporterService, protected loaderService: LoaderService) {
		// console.log('ModalGalleryDialogComponent', data);

		if (data.hasOwnProperty('usersAll')) {
			this.usersAll = data.usersAll;
		}
		if (data.hasOwnProperty('selectedCM') && data.hasOwnProperty('cmusers')) {
			this.selectedCM = data.selectedCM;
			this.cmusers = data.cmusers;
			if (data.selectedCM !== 'all') {
				this.selectedCMs = [data.selectedCM]
			} else {
				this.selectedCMs = data.cmusers.map((itm) => {
					return itm._id;
				});
			}
			for (let itm of data.cmusers) {
				this.selectedCMsObject[itm._id] = itm;
			}
		}
		if (data.hasOwnProperty('selectedClient') && data.hasOwnProperty('users')) {
			this.selectedClient = data.selectedClient;
			this.users = data.users;
			if (data.selectedClient !== 'all' && data.selectedClient !== 'no-selection') {
				this.selectedClients = [data.selectedClient]
			} else if (data.selectedClient === 'no-selection') {
				this.selectedClients = [];
			} else {
				this.selectedClients = data.users.map((itm) => {
					return itm._id;
				});
			}
			for (let itm of data.users) {
				this.selectedClientsObject[itm._id] = itm;
			}
		}
		if (data.hasOwnProperty('clients')) {
			this.clients = data.clients;
		}
		if (data.hasOwnProperty('metaFieldSetting')) {
			this.metaFieldSetting = data.metaFieldSetting;
			if (this.metaFieldSetting['safe'] && this.metaFieldSetting['safe'].enum) {
				this.safeEnumListObj = {};
				for (let enm of this.metaFieldSetting['safe'].enum) {
					this.safeEnumListObj[enm.value] = enm.displayName;
				}
			}
			if (this.metaFieldSetting['wrna'] && this.metaFieldSetting['wrna'].enum) {
				this.wrnaEnumListObj = {};
				for (let enm of this.metaFieldSetting['wrna'].enum) {
					this.wrnaEnumListObj[enm.value] = enm.displayName;
				}
			}
		}
		if (data.hasOwnProperty('momentDateFormat')) {
			this.momentDateFormat = data.momentDateFormat;
		}
		// console.log('this.clients', this.clients);
		// console.log('this.users', this.users);
		// console.log('this.selectedClients', this.selectedClients);
		// console.log('this.metaFieldSetting', this.metaFieldSetting);
		let currentStartDateStart = moment().clone().subtract(1, 'month');
		let currentEndDateStart = moment().clone();
		this.selectedStartDate = currentStartDateStart.toDate();
		this.selectedEndDate = currentEndDateStart.toDate();
		if (this.clients) {
			this.selectSAFE(['all']);
			this.selectWrna(['all']);
		}

	}
	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.isAdmin = this.requestService.isUserRoleAdmin();
					this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
					this.isCaseManagerSuper = this.requestService.getUserRole() === 'superedit';
					this.buildSettingCategory();
					this.buildSettingSubCategory();
				}
			})
		);
	}
	buildSettingCategory() {
		if (this.selectedCategory == 'all') {
			this.metaFieldSetting['subcategory'].enum.map((itm) => {
				itm['hide'] = false;
			});
			this.selectedSubCategory = 'all';
			this.selectedFacility = 'all';
		} else {
			this.selectedSubCategory = 'all';
			this.selectedFacility = 'all';
			this.metaFieldSetting['subcategory'].enum.map((itm) => {
				if (itm.hasOwnProperty('category') && (itm.category === '' || itm.category === this.selectedCategory)) {
					itm['hide'] = false;
				} else {
					itm['hide'] = true;
				}
			});
			if (this.selectedCategory == '2') {
				this.metaFieldSetting['facility']['disabled'] = false;
			} else {
				this.selectedFacility = 'all';
				this.metaFieldSetting['facility']['disabled'] = true;
			}
		}
	}
	buildSettingSubCategory() {
		if (this.selectedSubCategory == 'all' || ['16', '17'].includes(this.selectedSubCategory)) {
			this.metaFieldSetting['facility']['disabled'] = false;
		} else {
			this.selectedFacility = 'all';
			this.metaFieldSetting['facility']['disabled'] = true;
		}
	}
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}
	onMenuOpened(): void {
		this.menuIsOpen = true;
	}

	onMenuClosed(): void {
		this.menuIsOpen = false;
		let currentStartDate = moment(this.selectedStartDate).startOf('day');
		let currentEndDate = moment(this.selectedEndDate).endOf('day');
		this.selectedStartDate = currentStartDate.toDate();
		this.selectedEndDate = currentEndDate.toDate();
	}
	startMenuClose(event: Event): void {
		// Prevent the click event from propagating up to the MatMenu
		event.stopPropagation();
	}
	endMenuClose(event: Event): void {
		// Prevent the click event from propagating up to the MatMenu
		event.stopPropagation();
	}
	// startMenuChange(event: Event): void {
	// 	if (this.selectedEndDate >= event) {
	// 		this.selectedStartDate = event;
	// 	} else {
	// 		this.layoutUtilsService.showNotification(this.translate.instant('Start Date should be less than End Date'), this.translate.instant('Dismiss'));
	// 	}
	// }
	// endMenuChange(event: Event): void {
	// 	if (this.selectedStartDate <= event) {
	// 		this.selectedEndDate = event;
	// 	} else {
	// 		this.layoutUtilsService.showNotification(this.translate.instant('End Date should be greater than Start Date'), this.translate.instant('Dismiss'));
	// 	}
	// }
	isValid() {
		if (!this.selectedStartDate) {
			this.layoutUtilsService.showNotification(this.translate.instant('Start Date has invalid format'), this.translate.instant('Dismiss'));
			return false;
		}
		if (!this.selectedEndDate) {
			this.layoutUtilsService.showNotification(this.translate.instant('End Date has invalid format'), this.translate.instant('Dismiss'));
			return false;
		}
		if (this.selectedStartDate > this.selectedEndDate) {
			this.layoutUtilsService.showNotification(this.translate.instant('Start Date should be less than End Date'), this.translate.instant('Dismiss'));
			return false;
		}
		return true;
	}
	public setSelectedCM(val) {
		this.selectedCM = val;
		if (this.selectedCM !== 'all') {
			this.selectedCMs = [this.selectedCM];
		} else {
			this.selectedCMs = this.cmusers.map((itm) => {
				return itm._id;
			});
		}
		let userClientsAll = JSON.parse(JSON.stringify(this.usersAll));
		if (val === 'all') {
			this.users = userClientsAll;
			this.selectedClient = 'no-selection';
			this.selectedClients = [];
		} else {
			this.users = userClientsAll.filter((itm) => itm.ref_id._id === val);
			this.selectedClient = 'no-selection';
			this.selectedClients = [];
		}
	}
	public setSelectedClient(val) {
		this.selectedClient = val;
		let userClientsAll = JSON.parse(JSON.stringify(this.users));
		if (this.selectedClient !== 'all' && this.selectedClient !== 'no-selection') {
			this.selectedClients = [this.selectedClient]
		} else if (this.selectedClient === 'no-selection') {
			this.selectedClients = [];
		} else {
			this.selectedClients = userClientsAll.map((itm) => {
				return itm._id;
			});
		}

	}
	downloadData() {
		if (!this.isValid()) {
			return;
		}
		if (!this.loading) {
			this.loading = true;
			this.loaderService.display(true);

			// let mom = moment;
			let currentStartDate = moment(this.selectedStartDate);
			let currentEndDate = moment(this.selectedEndDate);
			// if (this.currentDay) {
			// 	let fullDate = this.getFullDay(this.currentDay);
			// 	currentStartDate = moment(fullDate);
			// 	currentEndDate = moment(fullDate);
			// }

			// let termConfiguration = this.termConfiguration();
			let startFilter = currentStartDate.startOf('day').clone().utc();
			let endFilter = currentEndDate.endOf('day').clone().utc();

			let filters = {
				'foreign_key': { '$eq': this.requestService.orgId },
				'project': { '$eq': environment.projectName },
				'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
				'enddate': { '$lt': endFilter.format('YYYY-MM-DDTHH:mm') }
			}
			if (this.selectedCategory !== 'all') {
				filters['category'] = { '$eq': this.selectedCategory };
			}
			if (this.selectedSubCategory !== 'all') {
				filters['subcategory'] = { '$eq': this.selectedSubCategory };
			}
			if (this.selectedFacility !== 'all') {
				filters['facility'] = { '$eq': this.selectedFacility };
			}
			let selectedWrna = this.selectedWrna.filter((itm) => itm !== 'all');
			if (selectedWrna.length > 0 && selectedWrna.length !== this.metaFieldSetting['wrna'].enum.length) {
				filters['wrna'] = { '$in': selectedWrna };
			}
			let selectedSAFE = this.selectedSAFE.filter((itm) => itm !== 'all');
			if (selectedSAFE.length > 0 && selectedSAFE.length !== this.metaFieldSetting['safe'].enum.length) {
				filters['safe'] = { '$in': selectedSAFE };
			}
			filters["$or"] = this.commonFilter();
			let filterObj = { page: 1, perpage: 10000000, term: '', orderDir: 'desc', orderBy: 'startdate', fieldKeys: [], filter: filters };
			this.requestService.getDataList(this.dataType,
				filterObj
				, (data, error) => {
					//console.log(data);
					if (error) {
						this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
						this.loaderService.display(false);
						this.loading = false;
					}
					if (data) {
						let dataList = data.results || [];
						if (this.selectedExportType === 'calendar-items') {
							this.downloadCSV(dataList);
						} else if (this.selectedExportType === 'category-breakdown') {
							this.downloadCSVBreak(dataList);
						}
						this.loaderService.display(false);
						this.loading = false;
					}
				});
		}
	}
	commonFilter() {
		if (this.clients) {
			if (this.selectedClient !== 'no-selection') {
				return [
					{
						"createdBy": { "$in": this.selectedClients }
					},
					{
						"$and": [
							{
								"attendees._id": { "$in": this.selectedClients }
							}
						]
					}
				]
			} else {
				return [
					{
						"$and": [
							{ "attendees": { "$size": 0 } },
							{
								"createdBy": { "$in": this.selectedCMs }
							}
						]
					},
					{
						"$and": [
							{
								"attendees._id": { "$in": this.selectedCMs }
							}
						]
					}
				]
			}
		} else {
			return [
				{
					"$and": [
						{ "attendees": { "$size": 0 } },
						{
							"createdBy": { "$eq": this.selectedUser._id }
						}
					]
				},
				{
					"$and": [
						{ "attendees._id": this.selectedUser._id },
						{
							"createdBy": { "$ne": this.selectedUser._id }
						}
					]
				}
			]
		}
	}
	getEmptyCsvLine() {
		let csvLine: any = {
		}
		if (this.clients) {
			if (this.selectedClient !== 'no-selection') {
				csvLine['client_id'] = '';
				csvLine['birth_year'] = '';
				csvLine['age_started'] = '';
			} else {
				csvLine['case_manager_name'] = '';
				csvLine['birth_year'] = '';
				csvLine['age_started'] = '';
			}
		}
		csvLine['date'] = '';
		csvLine['start_time'] = '';
		csvLine['end_time'] = '';
		csvLine['recurrence'] = '';
		csvLine['title'] = '';
		csvLine['type(online/in-person)'] = '';
		csvLine['details(location/meeting-url)'] = '';
		csvLine['main_category'] = '';
		csvLine['subcategory'] = '';
		csvLine['facility'] = '';
		csvLine['safe'] = '';
		csvLine['wrna'] = '';
		csvLine['participation nb'] = '';
		csvLine['description notes'] = '';
		return csvLine;
	}
	getDataCsvLine(itm) {
		let csvLine: any = this.getEmptyCsvLine()
		if (this.clients) {
			if (this.selectedClient !== 'no-selection') {
				csvLine['client_id'] = itm.client_id;
				csvLine['birth_year'] = itm.birth_year;
				csvLine['age_started'] = itm.age_started;
			} else {
				csvLine['case_manager_name'] = itm.case_manager_name;
				csvLine['birth_year'] = itm.birth_year;
				csvLine['age_started'] = itm.age_started;
			}
		}
		csvLine['date'] = moment(itm.startdate).format(this.momentDateFormat);
		csvLine['start_time'] = moment(itm.startdate).format('hh:mm A');
		csvLine['end_time'] = moment(itm.enddate).format('hh:mm A');
		csvLine['recurrence'] = itm.isRecurrence && itm.recurrence ? itm.recurrence?.humantext : 'No';
		csvLine['title'] = itm.name;
		csvLine['type(online/in-person)'] = this.getDisplayName(this.metaFieldSetting['meetingType'].enum, 'value', 'displayName', itm.meetingType);
		csvLine['details(location/meeting-url)'] = itm.meetingType == 1 ? itm.meetingurl : itm.location;
		csvLine['main_category'] = this.getDisplayName(this.metaFieldSetting['category'].enum, 'value', 'displayName', itm.category);
		csvLine['subcategory'] = this.getDisplayName(this.metaFieldSetting['subcategory'].enum, 'value', 'displayName', itm.subcategory);
		csvLine['facility'] = this.getDisplayName(this.metaFieldSetting['facility'].enum, 'value', 'displayName', itm.facility);
		csvLine['safe'] = this.getEnumDisplay(itm.safe, this.safeEnumListObj);
		csvLine['wrna'] = this.getEnumDisplay(itm.wrna, this.wrnaEnumListObj);
		csvLine['participation nb'] = itm.partno;
		csvLine['description notes'] = itm.description;
		return csvLine;
	}
	public getEnumDisplay(itms, newEnumListObj) {
		if (itms && itms.length > 0) {
			return itms.map((itm) => {
				return newEnumListObj[itm];
			})
		} else {
			return '';
		}
	}
	public getDisplayName(lst: any[], idKey: string = '_id', idName: string = 'name', value: string = '') {
		for (let itm of lst) {
			if (itm[idKey] === value) {
				return itm[idName];
			}
		}
		return undefined;
	}
	public downloadCSV(dataList) {
		const items: any[] = [];
		let csvLine: any = this.getEmptyCsvLine();

		if (dataList.length > 0) {
			for (let itm of dataList) {
				if (this.clients && itm.attendees && itm.attendees.length > 0) {
					if (this.selectedClient !== 'all' && this.selectedClient !== 'no-selection') {
						if (this.selectedClientsObject[this.selectedClient]) {
							itm['client_id'] = this.selectedClientsObject[this.selectedClient]['client_id'] || '';
							let birth_year = '';
							let age_started = '';
							if (this.selectedClientsObject[this.selectedClient]['dob']) {
								let dateDOB = moment(this.selectedClientsObject[this.selectedClient]['dob']).local();
								let year = dateDOB.year().toString();
								birth_year = year || '';
								if (this.selectedClientsObject[this.selectedClient]['date_started']) {
									let dateStarted = moment(this.selectedClientsObject[this.selectedClient]['date_started']).local();
									age_started = dateStarted.diff(dateDOB, 'years').toString();
								}

							}
							itm['birth_year'] = birth_year
							itm['age_started'] = age_started;
						}
						csvLine = this.getDataCsvLine(itm);
						items.push(csvLine);
					} else if (this.selectedClient === 'no-selection') {
						csvLine = this.getDataCsvLine(itm);
						items.push(csvLine);
					} else {

						for (let client of itm.attendees) {
							if (this.selectedClientsObject[client._id]) {
								itm['client_id'] = this.selectedClientsObject[client._id]['client_id'] || '';
								let birth_year = '';
								let age_started = '';
								if (this.selectedClientsObject[client._id]['dob']) {
									let dateDOB = moment(this.selectedClientsObject[client._id]['dob']).local();
									let year = dateDOB.year().toString();
									birth_year = year || '';
									if (this.selectedClientsObject[client._id]['date_started']) {
										let dateStarted = moment(this.selectedClientsObject[client._id]['date_started']).local();
										age_started = dateStarted.diff(dateDOB, 'years').toString();
									}

								}
								itm['birth_year'] = birth_year
								itm['age_started'] = age_started;
								csvLine = this.getDataCsvLine(itm);
								items.push(csvLine);
							}

						}
					}

				} else {
					if (this.selectedClientsObject[itm.createdBy]) {
						itm['client_id'] = this.selectedClientsObject[itm.createdBy]['client_id'] || '';
						let birth_year = '';
						let age_started = '';
						if (this.selectedClientsObject[itm.createdBy]['dob']) {
							let dateDOB = moment(this.selectedClientsObject[itm.createdBy]['dob']).local();
							let year = dateDOB.year().toString();
							birth_year = year || '';
							if (this.selectedClientsObject[itm.createdBy]['date_started']) {
								let dateStarted = moment(this.selectedClientsObject[itm.createdBy]['date_started']).local();
								age_started = dateStarted.diff(dateDOB, 'years').toString();
							}

						}
						itm['birth_year'] = birth_year
						itm['age_started'] = age_started;
					} else if (this.selectedCMsObject[itm.createdBy]) {
						itm['case_manager_name'] = this.selectedCMsObject[itm.createdBy]['name'] || '';
						let birth_year = '';
						let age_started = '';
						if (this.selectedCMsObject[itm.createdBy]['dob']) {
							let dateDOB = moment(this.selectedCMsObject[itm.createdBy]['dob']).local();
							let year = dateDOB.year().toString();
							birth_year = year || '';
							if (this.selectedCMsObject[itm.createdBy]['date_started']) {
								let dateStarted = moment(this.selectedCMsObject[itm.createdBy]['date_started']).local();
								age_started = dateStarted.diff(dateDOB, 'years').toString();
							}

						}
						itm['birth_year'] = birth_year
						itm['age_started'] = age_started;
					}
					csvLine = this.getDataCsvLine(itm);
					items.push(csvLine);
				}
			}
		} else {
			items.push(csvLine);
		}

		let date = new Date();
		let formattedDate = moment(date).format('YYYYMMDDHHmm');
		let selectedClient = this.selectedClient;
		if (this.selectedClient === 'no-selection') {
			if (this.selectedCM === 'all') {
				selectedClient = 'allcms';
			} else {
				selectedClient = this.selectedCMsObject[this.selectedCM].name;
			}
		}
		let fileName = sanitizeFilename(formattedDate + '_' + selectedClient + ".csv");
		this.csvService.exportToCsv(fileName, items);
		this.closeModal(undefined);
	}
	getEmptyCsvLineBreak() {
		let csvLine: any = {
		}
		csvLine['main_category'] = '';
		csvLine['time_spent'] = '';
		csvLine['subcategory'] = '';
		csvLine['time_spent2'] = '';

		return csvLine;
	}
	public downloadCSVBreak(dataList) {
		const items: any[] = [];
		let csvLine: any = this.getEmptyCsvLineBreak();

		let dataAnalyzed = this.prepareDataAnalysis(dataList);
		if (dataAnalyzed.length > 0) {
			let idx = 0;
			for (let event of dataAnalyzed) {
				csvLine = this.getEmptyCsvLineBreak();
				if (event.subcategory) {
					let subcategory = event.subcategory;
					csvLine['subcategory'] = subcategory.displayName;
					if (subcategory.total > 0) {
						csvLine['time_spent2'] = subcategory.total + ' ' + subcategory['unit'];
						if (subcategory.totalMin > 0) {
							csvLine['time_spent2'] = csvLine['time_spent2'] + ' ';
						}
					}
					if (subcategory.totalMin > 0) {
						csvLine['time_spent2'] = csvLine['time_spent2'] + subcategory.totalMin + ' ' + subcategory['unitMin'];
					}
				}
				if (event.category) {
					let mainCategory = event.category;
					csvLine['main_category'] = mainCategory.displayName;
					if (mainCategory.total > 0) {
						csvLine['time_spent'] = mainCategory.total + ' ' + mainCategory['unit'];
						if (mainCategory.totalMin > 0) {
							csvLine['time_spent'] = csvLine['time_spent'] + ' ';
						}
					}
					if (mainCategory.totalMin > 0) {
						csvLine['time_spent'] = csvLine['time_spent'] + mainCategory.totalMin + ' ' + mainCategory['unitMin'];
					}
				}

				items.push(csvLine);
				idx++;
			}
		} else {
			items.push(csvLine);
		}
		let date = new Date();
		let formattedDate = moment(date).format('YYYYMMDDHHmm');
		let selectedClient = this.selectedClient;
		if (this.selectedClient === 'no-selection') {
			if (this.selectedCM === 'all') {
				selectedClient = 'allcms';
			} else {
				selectedClient = this.selectedCMsObject[this.selectedCM].name;
			}
		}
		let fileName = sanitizeFilename(formattedDate + '_' + selectedClient + ".csv");
		this.csvService.exportToCsv(fileName, items);
		this.closeModal(undefined);
	}
	selectWrna(value) {
		let selectedWrna = this.analyzeMultiple(this.selectedWrna, value, this.metaFieldSetting['wrna'].enum);
		this.selectedWrna = selectedWrna;
	}
	selectSAFE(value) {
		let selectedSAFE = this.analyzeMultiple(this.selectedSAFE, value, this.metaFieldSetting['safe'].enum);
		this.selectedSAFE = selectedSAFE;
	}
	// analyzeMultiple(preValue, value, dataOptions) {
	// 	let selectedData = [];
	// 	if (value.includes('all')) {
	// 		if (preValue.length === dataOptions.length) {
	// 			selectedData = [];
	// 		} else {
	// 			selectedData = dataOptions.map((itm) => {
	// 				return itm.value;
	// 			});
	// 		}
	// 	} else {
	// 		selectedData = value;
	// 	}
	// 	return selectedData;
	// }
	analyzeMultiple(preValue, value, dataOptions) {
		let isAll = preValue.includes('all');
		let selectedData = [];
		if (isAll) {
			if (!value.includes('all')) {
				selectedData = [];
			} else {
				selectedData = value.filter((item) => {
					return item !== 'all';
				});
			}
		} else {
			if (value.includes('all')) {
				selectedData = dataOptions.map((itm) => {
					return itm.value;
				});
				selectedData.push('all');
			} else {
				selectedData = value;
			}
		}
		return selectedData;
	}
	prepareDataAnalysis(events) {
		let analyzedEventsMain = {};
		let analyzedEvents = {};
		let dataListFiltered = events;
		// if (this.clients && this.selectedClient === 'all') {
		// 	dataListFiltered = events.filter((itm) => itm.attendees && itm.attendees.length > 1);
		// }
		for (let itm of dataListFiltered) {
			if (!analyzedEventsMain.hasOwnProperty(itm.category)) {
				analyzedEventsMain[itm.category] = 0;
			}
			let startdate = moment(itm.startdate).utc();
			let enddate = moment(itm.enddate).utc();
			let totalMinutes = enddate.diff(startdate, 'minutes');
			if (itm.subcategory !== '' && itm.subcategory !== '24') {
				if (!analyzedEvents.hasOwnProperty(itm.subcategory)) {
					analyzedEvents[itm.subcategory] = 0;
				}
				analyzedEvents[itm.subcategory] = analyzedEvents[itm.subcategory] + totalMinutes;
			}
			analyzedEventsMain[itm.category] = analyzedEventsMain[itm.category] + totalMinutes;
		}
		for (let itm of Object.keys(analyzedEvents)) {
			let totalMinutes = analyzedEvents[itm];
			let hours = Math.floor(totalMinutes / 60);
			let remainingMinutes = totalMinutes % 60;
			analyzedEvents[itm] = { displayName: this.getDisplayName(this.metaFieldSetting['subcategory'].enum, 'value', 'displayName', itm), id: itm, hours: hours, minutes: remainingMinutes, type: 'subcategory' };
		}
		for (let itm of Object.keys(analyzedEventsMain)) {
			let totalMinutes = analyzedEventsMain[itm];
			let hours = Math.floor(totalMinutes / 60);
			let remainingMinutes = totalMinutes % 60;

			analyzedEventsMain[itm] = { displayName: this.getDisplayName(this.metaFieldSetting['category'].enum, 'value', 'displayName', itm), id: itm, hours: hours, minutes: remainingMinutes, type: 'category' };
		}

		let returnedDataList = [];

		let analyzedEventsArray = Object.keys(analyzedEvents);
		let analyzedEventsMainArray = Object.keys(analyzedEventsMain);
		let maxLength: number = analyzedEventsMainArray.length > analyzedEventsArray.length ? analyzedEventsMainArray.length : analyzedEventsArray.length;
		for (let itm = 0; itm < maxLength; itm++) {
			let category = undefined;
			let subcategory = undefined;
			if (analyzedEventsMainArray[itm]) {
				category = this.getItemData(analyzedEventsMain[analyzedEventsMainArray[itm]]);
			}
			if (analyzedEventsArray[itm]) {
				subcategory = this.getItemData(analyzedEvents[analyzedEventsArray[itm]]);
			}
			returnedDataList.push({ category: category, subcategory: subcategory });
		}
		return returnedDataList;
	}
	getItemData(itm) {
		itm['total'] = itm['hours'] ? itm['hours'] : 0;
		itm['totalMin'] = itm['minutes'] ? itm['minutes'] : 0;
		itm['unit'] = itm['total'] < 2 ? 'hr' : 'hrs';
		itm['unitMin'] = itm['totalMin'] < 2 ? 'min' : 'mins';
		return itm;
	}
}
