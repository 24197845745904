import { Component, Inject, OnInit, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { utc } from 'moment';
import { ICalendar, CalendarOptions, GoogleCalendar, YahooCalendar, OutlookCalendar } from 'datebook';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { RequestService } from '../../services/request.service';
import { ModalFilesEventDialogComponent } from '../custom-files-event-dialog/custom-files-event-dialog.component';
import { ModalSendEmailDialogComponent } from '../custom-send-email-dialog/custom-send-email-dialog.component';
declare var rrule: any;

interface ViewDialogData {
	title: string;
	data: any;
	fields: any;
	confirmData: any;
	extraButtons: any[];
	dateFormat: string;
	showAddToPersonalCalendar: boolean;
	canEdit: boolean;
	canDelete: boolean;
	canOpen: boolean;
	canCreate: boolean;
	contentTemplate: TemplateRef<any>;
}

@Component({
	selector: 'app-view-event-dialog-modal',
	templateUrl: './custom-view-event-dialog.component.html',
	styleUrls: ['./custom-view-event-dialog.component.scss']
})
export class CustomModalViewEventDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public ruleText: string = '';
	public canEdit: boolean = false;
	public canDelete: boolean = false;
	public canCreate: boolean = false;
	public canOpen: boolean = false;
	public showAddToPersonalCalendar: boolean = false;
	public extraButtons: any[] = [];
	public selectedEvent: any = undefined;
	public metaFieldSetting = undefined;
	public dateFormat: string = 'dd-MM-yyyy';
	public contentTemplate: TemplateRef<any> = undefined;
	utc = utc;
	// public currentTzOffset = moment().utcOffset();
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
	constructor(protected translate: TranslateService,
		private requestService: RequestService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<CustomModalViewEventDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalViewEventDialogComponent', data);
		if (this.data.fields) {
			this.metaFieldSetting = this.buildMetaSetting(this.data, parent = undefined);
		}
		if (this.data.contentTemplate) {
			this.contentTemplate = this.data.contentTemplate;
		}
		// console.log('this.metaFieldSetting', this.metaFieldSetting);
		if (data.data) {
			this.selectedEvent = data.data;
			if (this.selectedEvent.isRecurrence && this.selectedEvent.recurrence) {
				this.setRuleText(this.selectedEvent.recurrence);
			}
		}
		if (data.canEdit) {
			this.canEdit = data.canEdit;
		}
		if (data.canCreate) {
			this.canCreate = data.canCreate;
		}
		if (data.canDelete) {
			this.canDelete = data.canDelete;
		}
		if (data.canOpen) {
			this.canOpen = data.canOpen;
		}
		if (data.dateFormat) {
			this.dateFormat = data.dateFormat;
		}
		if (data.extraButtons) {
			this.extraButtons = data.extraButtons;
		}
		if (data.showAddToPersonalCalendar) {
			this.showAddToPersonalCalendar = data.showAddToPersonalCalendar;
		}
	}

	ngOnInit() {
	}
	setRuleText(recurrence) {
		// console.log('this.returnData', this.returnData);
		try {
			this.ruleText = this.getRecurrenceDatas(recurrence).humantext;
			this.selectedEvent['humantext'] = this.ruleText;
		} catch (e) {
			// error
			console.log('setRuleText', e);
		}
	}
	getRecurrenceDatas(recurObj) {
		let response = { humantext: '', all: undefined };
		if (!recurObj) {
			return response;
		} else {
			let pattern = "";
			let patternView = "";
			let count = 0;
			switch (recurObj["repeats"]) {
				case "minutely":
					pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
					break;
				case "hourly":
					pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
					break;
				case "daily":
					pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
					break;
				case "weekly":
					pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
					break;
				case "monthly":
					pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
					if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
						pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						let bysetposSign = '+';
						if (recurObj["bysetpos"] < 0) {
							bysetposSign = '';
						}
						pattern += ";BYSETPOS=1;BYDAY=" + bysetposSign + recurObj["bysetpos"] + recurObj["byday"].toString();
					}
					break;
				case "yearly":
					pattern = "FREQ=YEARLY;"
					if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
						pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						let bysetposSign = '+';
						if (recurObj["bysetpos"] < 0) {
							bysetposSign = '';
						}
						pattern += "BYDAY=" + bysetposSign + recurObj["bysetpos"] + recurObj["byday"] + ";BYSETPOS=1;BYMONTH=" + recurObj["bymonth"].toString();
					}
					break;
				default:
					pattern = "";
			}
			patternView = pattern;
			if (recurObj["end"] && recurObj["end"] == "after") {
				pattern += ";COUNT=" + recurObj["count"];
				patternView += ";COUNT=" + recurObj["count"];
				count = recurObj["count"];
			}
			if (recurObj["tzid"]) {
				pattern += ";TZID=" + recurObj["tzid"];
			} else {
				pattern += ";TZID=" + this.getUserTimeZone();
			}
			if (recurObj["end"] && recurObj["end"] == "date") {
				pattern += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss');
				patternView += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss');
			}

			// console.log('pattern', pattern);
			let options = rrule.RRule.parseString(pattern);
			let optionsView = rrule.RRule.parseString(patternView);
			let startdate = new Date(recurObj["startdate"]);
			const utcStartDate = new Date(startdate.getTime() - startdate.getTimezoneOffset() * 60000);
			const utcMoment = moment.utc(recurObj["startdate"]);
			const localMoment = utcMoment.local();
			const formattedLocalTime = localMoment.format('YYYY-MM-DD hh:mm A');
			// let startdateUTC = new Date(Date.UTC(startdate.getFullYear(), startdate.getMonth(), startdate.getDate(), startdate.getHours(), startdate.getMinutes(), 0));
			options.dtstart = utcStartDate;
			// options.dtstart = moment(recurObj['startdate']).utc().toDate();
			optionsView.dtstart = utcStartDate;

			if (count && count != 0) {
				options.count = count;
				optionsView.count = count;
			} else {
				// options.until = new Date(recurObj["until"]);
				// options.until = moment(recurObj['until']).utc().toDate();
				// optionsView.until = moment(recurObj['until']).toDate();
			}
			if (recurObj["tzid"]) {
				options.tzid = recurObj["tzid"];
			} else {
				options.tzid = this.getUserTimeZone();
			}
			var rule = new rrule.RRule(options);
			var ruleView = new rrule.RRule(optionsView);
			// return rule.toText();
			var all = rule.all();
			response["tzid"] = recurObj["tzid"];
			response["localdate"] = utcStartDate;
			response["slocaldate"] = formattedLocalTime;
			response["humantext"] = ruleView.toText();
			response["all"] = all.map((occurrence: Date) => {
				occurrence = new Date(occurrence.getTime() + occurrence.getTimezoneOffset() * 60000);
				return occurrence;
			});
		}
		return response;
	}
	getUserTimeZone() {
		// Create a new date object with the current date and time
		const now = new Date();

		// Get the time zone from the date object using the Intl.DateTimeFormat API
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		return timeZone;
	}
	closeModal(data, target = undefined): void {
		if (target == 'self') {
			if (data === 'open_files') {
				this.showFilesEventDialog();
			} else if (data === 'send_email') {
				this.sendByEmail();
			}
		} else {
			this.dialogRef.close(data);
		}
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		// let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
			else if (col.type === 'table') {
				dataObject[col.name] = col;
			}
		}
		return dataObject;
	}
	cancelThru(e) {
		// if (e) {
		// 	e.stopImmediatePropagation();
		// 	e.preventDefault();
		// 	e.stopPropagation();
		// }
	}
	addtoMyCalender(e, type = "download") {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
		}
		let newLine = '\n';
		let record = this.data.data;
		let targetLocation = '';
		let targetUrl = '';

		if (type === 'google' || type === 'outlook') {
			newLine = '<br>';
		}

		if (record.category && record.category !== '') {
			targetUrl = targetUrl + this.metaFieldSetting['category'].displayName + ': ' + this.requestService.getItemFromListContainsDisplay(this.metaFieldSetting['category'].enum, record.category, 'value', 'displayName');
			if (record.category === '4') {
				targetUrl = targetUrl + ' (' + record.others + ')';
			}
			targetUrl = targetUrl + newLine;
		}
		if (record.subcategory && record.subcategory !== '') {
			targetUrl = targetUrl + this.metaFieldSetting['subcategory'].displayName + ': ' + this.requestService.getItemFromListContainsDisplay(this.metaFieldSetting['subcategory'].enum, record.subcategory, 'value', 'displayName');
			if (record.subcategory === '23') {
				targetUrl = targetUrl + ' (' + record.others + ')';
			}
			targetUrl = targetUrl + newLine;
		}
		if (record.facility && record.facility !== '') {
			targetUrl = targetUrl + this.metaFieldSetting['facility'].displayName + ': ' + this.requestService.getItemFromListContainsDisplay(this.metaFieldSetting['facility'].enum, record.facility, 'value', 'displayName') + newLine;
		}
		if (record.partno && record.partno !== '' && record.partno > 0) {
			targetUrl = targetUrl + this.metaFieldSetting['partno'].displayName + ': ' + record.partno + newLine;
		}
		if (record.description && record.description !== '') {
			targetUrl = targetUrl + this.metaFieldSetting['description'].displayName + ': ' + record.description + newLine;
		}
		if (record.meetingType === '1' && record.meetingurl !== '') { // online
			if (type === 'google' || type === 'outlook') {
				targetUrl = targetUrl + this.metaFieldSetting['meetingurl'].displayName + ': ' + '<a href="' + record.meetingurl + '">' + record.meetingurl + '</a>';
			} else {
				// targetUrl = targetUrl + this.metaFieldSetting['meetingurl'].displayName + ': ' + '[Link](' + record.meetingurl + ')' + newLine;
				targetUrl = targetUrl + this.metaFieldSetting['meetingurl'].displayName + ': ' + record.meetingurl + newLine;
			}

		} else if (record.meetingType === '2' && record.location !== '') { // inperson
			targetLocation = record.location;
		}
		this.trigger.closeMenu();
		let config: CalendarOptions = {
			title: record.name,
			location: targetLocation,
			description: targetUrl,
			start: new Date(record.startdate),
			end: new Date(record.enddate),
		}
		if (record.isRecurrence && record.recurrence && record.repeats !== 'hourly') {
			config['recurrence'] = this.getRecurrence(record.recurrence);
		}
		console.log("config['recurrence']", config['recurrence']);
		if (type === 'download') {
			const icalendar = new ICalendar(config);
			const ics = icalendar.render();
			const blob = new Blob([ics], {
				type: 'text/calendar'
			})
			this.createDownloadLink(blob);
		} else if (type === 'google') {
			const googleCalendar = new GoogleCalendar(config);
			window.open(googleCalendar.render(), '_blank');
		} else if (type === 'yahoo') {
			const yahooCalendar = new YahooCalendar(config);
			window.open(yahooCalendar.render(), '_blank');
		} else if (type === 'outlook') {
			// config.start = this.convertUTCDateToLocalDate(config.start);
			// config.end = this.convertUTCDateToLocalDate(config.end);
			config.start = config.start;
			config.end = config.end;
			const outlookCalendar = new OutlookCalendar(config);
			let url = outlookCalendar.render();
			window.open(url, '_blank');
		}
	}
	private createDownloadLink(blob: Blob): void {
		const downloadLink = document.createElement('a');
		const url = window.URL.createObjectURL(blob);

		downloadLink.href = url;
		downloadLink.download = 'my-calendar-event.ics';

		// Append the link to the DOM
		document.body.appendChild(downloadLink);

		// Programmatically click the link to trigger the download
		downloadLink.click();

		// Remove the link from the DOM
		document.body.removeChild(downloadLink);

		// Release the object URL
		window.URL.revokeObjectURL(url);
	}
	getRecurrence(recurrence) {
		let returnRecurrence = {};
		if (recurrence.hasOwnProperty('repeats')) {
			returnRecurrence['frequency'] = recurrence.repeats.toUpperCase();
		}
		if (recurrence.hasOwnProperty('interval')) {
			returnRecurrence['interval'] = recurrence.interval;
		}
		if (recurrence.hasOwnProperty('count')) {
			returnRecurrence['count'] = recurrence.count;
		}
		if (recurrence.hasOwnProperty('until')) {
			returnRecurrence['end'] = new Date(recurrence.until);
		}
		if (recurrence.hasOwnProperty('byweekday')) {
			returnRecurrence['weekdays'] = recurrence.byweekday;
		}
		if (recurrence.hasOwnProperty('byday')) {
			let weekday = '';
			if (recurrence.hasOwnProperty('bysetpos')) {
				weekday = weekday + recurrence.bysetpos;
			}
			weekday = weekday + recurrence.byday;
			returnRecurrence['weekdays'] = [weekday];
		}
		if (recurrence.hasOwnProperty('bymonthday')) {
			returnRecurrence['monthdays'] = [recurrence.bymonthday];
		}
		// if (recurrence.hasOwnProperty('weekstart')) {
		// 	returnRecurrence['weekstart'] = recurrence.weekstart;
		// } else {
		returnRecurrence['weekstart'] = 'MO'
		// }
		if (recurrence.hasOwnProperty('bymonth')) {
			returnRecurrence['month'] = recurrence.bymonth;
		}

		return returnRecurrence;
	}
	showFilesEventDialog() {
		const dialogRef = this.dialog.open(ModalFilesEventDialogComponent, {
			width: '1600px',
			disableClose: false,
			autoFocus: false,
			data: {
				title: this.translate.instant('Select files or upload new ones'),
				multipleSelection: true,
				maxFiles: 5,
				targetId: this.selectedEvent._id,
				dataType: 'event',
				dataTypeDisplay: 'File',
				data: [],
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				console.log('nothing')
			}
		});
	}
	sendByEmail() {
		this.closeModal(undefined);
		const dialogRef = this.dialog.open(ModalSendEmailDialogComponent, {
			width: '1000px',
			disableClose: false,
			autoFocus: false,
			data: {
				title: this.translate.instant('Send by Email'),
				targetId: this.selectedEvent._id,
				data: [],
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				console.log('nothing')
			}
		});
	}
}