import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
// NGX Permissions
import { NgxPermissionsModule } from './shared/modules/ngx-permissions';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard, RequestService, StoreService, LoggerService, GlobalErrorHandler, LoaderService } from './shared';
import { LayoutUtilsService, MenuConfigService, SubheaderService } from './shared/services';
import { UserDefaultsService } from './shared';
import { PageScopeService, UrlHelperService } from './shared/services';
import { SharedModule } from './shared/shared.module';
import { LayoutComponentModule } from './shared/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GoogleMapsModule } from "@angular/google-maps";
import { environment } from '../environments/environment';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FirebaseHandlersService } from './shared/services/firebase-handlers.service';
import { rollbarFactory, RollbarService } from './shared/services/global-error.handler';
// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

// Function to initialize Firebase before app starts
export function initializeFirebase(firebaseService: FirebaseHandlersService) {
  return () => firebaseService.initialize();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    // NgxGraphModule,
    GoogleMapsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlayModule,
    SharedModule,
    LayoutComponentModule,
    HttpClientModule,
    ImageCropperModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    RequestService,
    LoaderService,
    StoreService,
    LayoutUtilsService,
    MenuConfigService,
    SubheaderService,
    LoggerService,
    CookieService,
    UserDefaultsService,
    PageScopeService,
    UrlHelperService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: RollbarService, useFactory: rollbarFactory },
    FirebaseHandlersService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFirebase,
      multi: true,
      deps: [FirebaseHandlersService],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
